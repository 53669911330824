.admin-page__container {
    width: 100vw;
    height: auto;
    margin: 1.5rem;
}

.table-container {
    width: 98%;
}

.admin-page__topContainer {
    display: flex;
    width: 100%;
    margin-bottom: 2rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.admin-page__input {
    width: 30vw;
    height: 2rem;
    font-size: 1rem;
    font-weight: 400;
    padding: 10px;
    margin: 0;
    border: 1px solid grey;
}

.admin-page__emailContainer {
    display: flex;
    margin-right: 3.5rem;
    justify-content: space-evenly;
    align-items: center;
}

.admin-page__email {
    width: 20vw;
    height: 2rem;
    font-size: 1rem;
    font-weight: 400;
    border: 1px solid grey;
    padding: 10px;
    margin: 0 0.25rem 0 0;
}

.admin-page__button__addUser.btn {
    position: relative;
    margin: 0;
    /* right: 4rem; */
    padding: 0;
    height: 2rem;
    width: auto;
    padding: 0;
}

.admin-auth {
    margin: 2rem;
}

.admin-auth__container {
    display: flex;
    justify-content: start;
    height: auto;
    width: 90vw;
}

.admin-auth__container input {
    height: calc(1.5em + .75rem + 2px);
    padding: 10px;
    margin: 1rem 1rem 0 0;
}

.admin-auth__container button {
    margin: 1.25rem 0;
    height: calc(1.5em + .75rem + 2px);
    width: auto;
    padding: 0;
}

.admin-auth__warning {
    color: red;
}

.admin-page__notifications {
    float: right;
    padding: 0;
    margin-top: -2rem;
    margin-right: 3.5rem; 
    height: auto;
    max-height: 2rem;
    transition: max-height 0.2s ease-in-out;
    -webkit-transition: max-height 0.2s ease-in-out;
    -moz-transition: max-height 0.2s ease-in-out;
}

.admin-page__tr:hover {
    cursor: pointer;
}