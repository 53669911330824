/************************************************************************************/
/************************************************************************************/
/* HERO */
h1{
  /* font-family: sofia-pro, sans-serif; */
}

#hero.ecosystem{
  background-image: url("../../images/ecoweb.png");
  }

/* FULL_WIDTH_IMAGE */
.full-width-image.calling-all-creators{
  background-image: url("../../images/Maker_1920.jpg");

}
  
/* Footer */
#footer.ecosystem{
  background-image: url("../../images/ecosystem_phone.png");
}


@media (min-width: 300px){
    #hero.ecosystem{
      background-image: url("../../images/ecomobile.png");
    }

    .full-width-image.calling-all-creators{
      background-image: none;
    }
    #footer.ecosystem{
      background-image: url("../../images/ecosystem_phone.png");
    }
  }

  
  @media (min-width: 600px){
    #hero.ecosystem{
      background-image: url("../../images/ecotablet.png");
    }

    .full-width-image.calling-all-creators{
      background-image: url("../../images/Maker_1024.jpg");
    }
    #footer.ecosystem{
      background-image: url("../../images/ecosystem_tab.png");
    }
  }
  
  @media (min-width: 1000px){
    #hero.ecosystem{
      background-image: url("../../images/ecoweb.png");
    }

    .full-width-image.calling-all-creators{
      background-image: url("../../images/Maker_1920.jpg");
    }
    #footer.ecosystem{
      background-image: url("../../images/ecosystem_web.png");
    }
  }


/************************************************************************************/
/************************************************************************************/

  
  