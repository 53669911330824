/* IMAGES TILE ON PROFILE */
#image-tile-wrapper {
  justify-content: flex-start;
}
#image-tile-wrapper .image-tile{
  min-height: 250px;
  width: 200px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
  border: 1px solid #cccccc;
  margin-bottom: 2rem;
  height: 90%;
  text-align: center;
  display:flex;
  flex-direction: column;
  /* justify-content: flex-end;
  align-items: flex-end; */
  justify-content: center;
}
#image-tile-wrapper .image-tile .img-fluid{
  /* max-height:100%;
  max-height: -moz-available;          
  max-height: -webkit-fill-available; 
  max-height: fill-available;
  flex:1; */
  width: 100%;
  height: auto;
}
#image-tile-wrapper .image-tile .icons-wrapper{
  opacity:0;
  position:absolute;
}
#image-tile-wrapper .image-tile:hover .icons-wrapper{
  opacity:1;
  position:absolute;
}
#image-tile-wrapper .image-tile:active .icons-wrapper{
  opacity:1;
  position:absolute;
}

#image-tile-wrapper .image-tile .document{
  width: 100%;
  width: -moz-available;          /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  width: fill-available;
  margin-bottom: 50%;
}

@media (min-width: 300px){
  #image-tile-wrapper .image-tile{
    height: 70%;
    width: 70%;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  #image-tile-wrapper {
    justify-content: center;
  }
}
@media (min-width: 600px){
  #image-tile-wrapper .image-tile{
    height: 90%;
    width: 200px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  #image-tile-wrapper {
    justify-content: flex-start;
  }
}
@media (min-width: 1000px){
  #image-tile-wrapper .image-tile{
    height: 90%;
  }
}

/* QUESTION MODAL */
#question-modal .modal-header, #question-modal .modal-footer{
  border:none;
}

#question-modal .selected-option{
  background-color: #000000;
  color:#FFFFFF;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  width:120px;
  margin: 5px;
  padding: 10px;
  text-align: center;
  /* font-family: sofia-pro; */
  font-weight: 400;
  border-radius: 5px;
  font-size: 12px;
}

#question-modal .unselected-option{
  background-color: #EBEBEB;
  color:#000000;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  width:120px;
  margin: 5px;
  padding: 10px;
  text-align: center;
  /* font-family: sofia-pro; */
  font-weight: 400;
  border-radius: 5px;
  font-size: 12px;
}

#question-modal .options-container{
  padding-left: 20%;
  padding-right: 20%;
  justify-content: center;
}

#question-modal .MuiSlider-root{
  color:#000000;
}

/* #question-modal .MuiSlider-track{
  background-color:#000000;
}

#question-modal .MuiSlider-rail{
  background-color:#a3a3a3;
}

#question-modal .MuiSlider-thumb{
  background-color:#000000;
}

#question-modal .PrivateValueLabel-circle-49{
  background-color:#000000;
}

#question-modal .PrivateValueLabel-circle-24, #question-modal .PrivateValueLabel-circle-74, #question-modal .PrivateValueLabel-circle-99, #question-modal .PrivateValueLabel-circle-124{
  background-color:#000000;
} */

#question-modal .dimensions-input{
  width:80px;
  text-align:center;
}

@media (min-width: 300px){
  #question-modal .options-container{
    padding-left: 0%;
    padding-right: 0%;
  }

  #question-modal .dimensions-input{
    width:50px;
  }
}

@media (min-width: 450px){
  #question-modal .options-container{
    padding-left: 6%;
    padding-right: 6%;
  }
}

@media (min-width: 600px){
  #question-modal .options-container{
    padding-left: 20%;
    padding-right: 20%;
  }
  #question-modal .dimensions-input{
    width:80px;
  }
}

@media (min-width: 1000px){
  #question-modal .options-container{
    padding-left: 20%;
    padding-right: 20%;
  }
}

/* DISPLAY QUESTIONS IN QUESTION TILES */
#questions .questions-container {
  display: flex;
  width: 100%;
  flex-wrap:wrap;
}
#questions .questions-container .question-tile-container {
  justify-content: space-between;
  margin:0.5rem;
  display: flex;
  flex-direction: column;
}

#questions .questions-container .question-tile-container .question-tile{
  display: flex;
  flex-direction: column;
  border: 1px solid;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  flex:1;
  width: 200px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
  border: 1px solid #cccccc;
  min-height:250px;
  text-align: center;
}

#questions .questions-container .question-tile-container .edit-question{
  opacity:0;
}

#questions .questions-container .question-tile-container:hover .edit-question{
  height:20px;
  text-align: right;
  cursor:pointer;
  opacity:1;
}

#questions .questions-container .question-tile-container:active .edit-question{
  height:20px;
  text-align: right;
  cursor:pointer;
  opacity:1;
}

#questions .questions-container .question-title{
  font-size: 14px;
  /* font-family: sofia-pro; */
  font-weight: 400;
}

#questions .questions-container .add-question, #questions .add-question{
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100px;
  font-family: sofia-rpo;
  margin-top: -40px;
  cursor: pointer;
}

#questions .questions-container .options-container{
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  justify-content: center;
  flex:1;
}

#questions .questions-container .unselected-option{
  background-color: #EBEBEB;
  color: #000000;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  margin: 5px;
  width: 44%;
  /* font-family: sofia-pro; */
  font-weight: 400;
  font-size: 10px;
  padding: 0.2rem;
  word-break: break-word;
  text-align: center;
}

@media (min-width: 300px){
  #questions .questions-container{
    justify-content: center;
  }
  #questions .questions-container .question-tile-container .question-tile{
    width: 100%;
  }
  #questions .questions-container .question-tile-container{
    width: 90%;
  }
}

@media (min-width: 600px){
  #questions .questions-container{
    justify-content: flex-start;
  }
  #questions .questions-container .question-tile-container .question-tile{
    width: 200px;
  }
  #questions .questions-container .question-tile-container{
    width: auto;
  }
}