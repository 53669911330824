@import url(https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98);
body {
  color: black;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --theme-color-1: rgba(87, 20, 172, 1);
  --theme-color-2: rgba(87, 20, 172, 0.3);
  --theme-color-3: rgba(158, 131, 191, 0.1);
  --theme-color-with-opacity: rgba(158, 131, 191, 0.3);
  --theme-color-1-hex: #5714ac;
}
html {
  scroll-behavior:smooth
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'rand black italic';
  src: url(/static/media/Rand-Black-Italic.30be897d.otf);
}
@font-face {
  font-family: 'rand black';
  src: url(/static/media/Rand-Black.c01aadca.otf);
}
@font-face {
  font-family: 'rand bold italic';
  src: url(/static/media/Rand-Bold-Italic.75d96d41.otf);
}
@font-face {
  font-family: 'rand bold';
  src: url(/static/media/Rand-Bold.71935b37.otf);
}
@font-face {
  font-family: 'rand heavy italic';
  src: url(/static/media/Rand-Heavy-Italic.e675fe7e.otf);
}
@font-face {
  font-family: 'rand heavy';
  src: url(/static/media/Rand-Heavy.662b8a70.otf);
}

@font-face {
  font-family: 'rand italic';
  src: url(/static/media/Rand-Italic.d2a7e08d.otf);
}
@font-face {
  font-family: 'rand light italic';
  src: url(/static/media/Rand-Light-Italic.3dcc304b.otf);
}
@font-face {
  font-family: 'rand light';
  src: url(/static/media/Rand-Light.32a9d6c4.otf);
}
@font-face {
  font-family: 'rand medium italic';
  src: url(/static/media/Rand-Medium-Italic.8bae44ef.otf);
}
@font-face {
  font-family: 'rand medium';
  src: url(/static/media/Rand-Medium.bde98676.otf);
}
@font-face {
  font-family: 'rand regular';
  src: url(/static/media/Rand-Regular.0adc30dd.otf);
}
@font-face {
  font-family: 'rand thin italic';
  src: url(/static/media/Rand-Thin-Italic.a8dbe588.otf);
}
@font-face {
  font-family: 'rand thin';
  src: url(/static/media/Rand-Thin.13d43f72.otf);
}

p, div, button, span
{
    font-family: 'rand regular';
}

h1, h2, h3
{
    font-family: 'rand heavy';
}

h4, h5, h6
{
    font-family: 'rand bold';
}

.navbar-brand
{
    font-family: 'rand bold';
  
}

.makeStyles-root-1 * {
  font-family: 'rand regular' !important;
}
body {
    font-family: sofia-pro-soft, sans-serif;
    font-weight: 700;
    font-style: normal;
}

#naya-navbar {
    margin-bottom: 2rem;
}

#naya-navbar .navbar-brand {
    /* font-family: bigmoore, serif; */
    font-size: 2rem;
    color: white;
}

h2 {
    font-family: orpheuspro, serif;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    font-size: 2.4rem;
    margin-bottom: 5rem;
}


#login-btn
{
    margin-top:2rem;
    margin-bottom: 2rem;
    text-transform:none;
    font-weight:bold;
    font-size:1.2rem;
    padding-left: 0;
    padding-right: 0;
    min-width: 10rem;
    display: flex;
    flex-basis: 0;
    justify-content: center;
    align-items: center;
    
}

#google-btn{
    background-color: white;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    font-family: 'Roboto', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 3.5rem;
    margin: auto;
    margin-top: 2rem !important; 
    padding: 0 2rem;
    font-size: 1.2rem;
    font-weight: 700;
    border: 1px solid #fafafa;
    border-radius: .25rem;
}

#facebook-btn{
    background-color: #3c5898;
    color: white;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    font-family: 'Roboto', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 3.5rem;
    margin: auto;
    margin-top: 2rem !important;
    padding: 0 2rem;
    font-size: 1.2rem;
    font-weight: 700;
    border: none !important;
    border-radius: .25rem !important;
    
}

.question-h2 {
    margin-bottom: 0rem;
    /* color: white; */
}

.question-h4 {
    margin-bottom: 4rem;
    /* color: white; */
}

h2 img {
    width: 1.5rem;
    height: 1.5rem;
}

button.btn {
    /* color: white; */
    /* background-color: black; */
    /* padding: 1rem; */
    /* font-size: 0.8rem; */
    padding-top: 0.8rem;

    padding-bottom: 0.8rem;
    padding-left: 5rem;
    padding-right: 5rem;
    border: none;
    font-weight: 700;
    text-transform: uppercase;
    /* border-radius: 50px; */
    min-width: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
}

.navbar-continue .btn {
    margin: 0;
    margin-top: 50%;
    transform: translate(0, -50%);
    /* position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 3rem; */
}

p.skip-button {
    width: 100%;
    /* margin-left: auto;
    margin-right: auto; */
    text-align: center;
    font-size: 0.9rem;
}

div.vertical-center {
    position: absolute;
    margin-top: 50vh;
    transform: translate(0, -25vh);
}

p.landing-option {
    text-align: center;
}

img.landing-option-img {
    border-radius: 100%;
}

.question-other-input {
    color: black;
    font-size: 0.85rem;
    font-weight: 700;
    text-align: center;
    padding: 0rem;
    border: 1px solid black;
    border-radius: 0;
}

.navbar-continue {
    display: none;
}



#register-wrapper {
    padding: 0rem;
    margin-top: 3rem;
}

#register-wrapper .btn {
    /* color: white; */
    border: 1px solid white;
    margin-top: 0rem;
    border-radius: 0;
    font-weight: 400;
}

input.form-control {
    /* background-color: black;
    color: white; */
    /* border: 1px solid white; */
    border-radius: 0px;
    padding: 1.3rem;
    margin-bottom: 1.5rem;
    margin-top: 0rem;
}

.auth-wrapper
{
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-left: 0px;
    padding-right: 0px;
}

h1 {
    /* font-family: bigmoore, serif; */
    font-weight: 700;
    margin-bottom: 1.5rem;
    font-size: 3.5rem;
}

h4 {
    font-weight: 100;
    font-size: 1rem;
}

h5 {
    /* font-weight: 100; */
    font-size: 1rem;
    margin-bottom: 1rem;
}

.wrapper {
    /* transform: translate(0, -40vh) */
}

@media (min-width: 768px) {
    .wrapper {
        /* transform: translate(0, -25vh) */
    }
}

.img-wrapper {
    padding: 0;
}

div.alert {
    font-weight: 400;
    font-size: 0.8rem;
}

h5 a {
    color: white;
    font-weight: 400;
}

.form-group select {
    background-color: black;
    color: white;
    border: 1px solid white;
    border-radius: 0px;
}

input {
    font-size: 22px;
    padding: 20px 40px;
    /* color: white; */
    /* background-color: black; */
    border: 2px solid black;
}


@media (min-width: 992px) {
    .navbar-continue {
        display: inline-block;
    }

    #register-wrapper {
        /* color: white; */
        padding: 3rem 0rem 3rem 0rem;
        /* position: absolute; */
        /* top: 50vh; */
        font-size: 1.1rem;
    
        /* position: fixed; */
        /* top: 50%; */
        /* left: 50%; */
        /* bring your own prefixes */
        /* transform: translate(-50%, -50%); */
        /* bottom: 0;
        left: 0;
        right: 0; */
    
        margin: auto;
        /* transform: translate(0, -25vh); */
    }

    
}

.info{
    text-align: center;
}

.box{
    min-width: 200px;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
}

.box.checked{
    background-color: black !important;
    border: 1px solid black !important;
    color: white;
}

.box:hover{
    background-color: darkgrey;
    color: white;
    border: 1px solid darkgrey;
}

.form-group{
    padding: 0 2rem;
}

@media only screen and (max-width: 425px) {
    .vertical {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
  }

body {
    /* background-color: #222; */
}

.canvas-tile-wrapper {
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

#canvas {
    padding: 1rem;
    font-family: sofia-pro-soft, sans-serif;
    font-weight: 700;
    font-style: normal;
}

#canvas h5 {
    font-family: bigmoore, serif;
    margin-top: 1rem;
    /* color: white; */
}

.canvas-tile-wrapper.image-tile {
    border-radius: 0.5rem;
}

.canvas-tile-wrapper .sketch {
    padding: 0px;
    /* max-height: 300px; */
}

.canvas-tile-wrapper.image-tile > div {
    padding: 0px;
}

.canvas-tile-wrapper.image-tile.upload img {
    /* max-height: 300px; */
}

.canvas-tile-wrapper.image-tile .canvas-tile {
    margin-bottom: 0px;
}

.canvas-tile-wrapper.image-tile img {
    height: 100%;
    width: auto;
    position: absolute;
    top: -9999px;
    left: -9999px;
    right: -9999px;
    bottom: -9999px;
    margin: auto;
}

.canvas-tile {
    min-height: 300px;

    border-radius: 0.5rem;
    /* margin-bottom: 1rem; */
    /* border: 1px solid #777; */
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    height: 90%;
    padding-top: 2rem;
    /* background-color: #111; */
    padding: 0rem;
}

.canvas-tile p {
    font-weight: 200;
    /* color: white; */
}

.canvas-tile:hover {
    background-color: #eee;
    transition-duration: 0.2s;
}

.canvas-tile h5 {
    text-align: center;
    font-size: 1.1rem;
}

.canvas-tile p.option-text {
    text-align: center;
    font-size: 0.85rem;
}

.canvas-tile > div {
    height: 100%;
    position: relative;
    /* transform: translate(0, -50%); */
}

.canvas-tile {
    overflow: hidden;
}

.canvas-tile > div > div {
    /* margin-top: 50%;
    transform: translate(0, -50%); */
}

#canvas .comment-tile {
    min-height: 300px;
    /* border: 1px solid #777; */
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    border-radius: 0.5rem;
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    overflow: hidden;
    font-weight: 400;
    /* background-color: #eee; */
    transition: all 0.2s ease-in-out;
    margin-top: 0rem;
    margin-bottom: 0rem;
}

#canvas .comment-tile:hover {
    /* transform: scale(1.01); */
}

#canvas .reply-to {
    /* background-color: #111; */
    /* padding: 0px; */
    opacity: 0.8;
}

#canvas .reply-to .canvas-tile {
    padding: 0px;
}

#canvas .reply-to .canvas-tile img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
    height: auto;
}

#canvas .comment-tile .btn {
    width: 100%;
    background-color: white;
    color: black;
    border: 1px solid black;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

#canvas .comment-tile p {
    font-weight: 200;
    font-size: 1.1rem;
    /* margin-top: 1rem; */
    padding: 1rem;
}

#canvas .comment-tile-render {
    overflow: hidden;
    /* background-color: #111; */
}

#canvas .user-profile {
    background-color: transparent;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
}

#canvas .user-profile img {
    height: 2rem;
    border-radius: 2rem;
    width: auto;
    /* margin-right: 1rem; */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: auto;
    margin-bottom: auto;
}

#canvas .user-profile .name {
    font-weight: 200;
    margin-bottom: 0px;
    margin-left: 2rem;
}

#canvas .user-profile .time {
    font-weight: 200;
    font-size: 0.85rem;
    color: #888888;
    margin-bottom: 0rem;
    margin-left: 2rem;
    margin-top: -0.2rem;
}

#canvas .comment-tile img,
#canvas .canvas-tile .img {
    height: 100%;
    width: auto;
    position: absolute;
    top: -9999px;
    left: -9999px;
    right: -9999px;
    bottom: -9999px;
    margin: auto;
}

#canvas .add-content-tile {
    min-height: 300px;
    /* background-color: rgba(255,255,255,0.3); */
    /* border: 1px solid #777; */
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    border-radius: 0.5rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

#canvas .add-content-tile:hover {
    /* min-height: 300px; */
    /* background-color: rgba(255,255,255,0.8); */
    /* border: 1px solid #888888; */
}

@media (min-width: 992px) {
    #canvas {
        padding: 4rem;
    }

    #canvas .tile-controls {
        opacity: 0;
    }

    #canvas .canvas-tile-wrapper:hover .tile-controls {
        opacity: 1;
    }

    #canvas .canvas-tile-wrapper:active .tile-controls {
        opacity: 1;
    }

    #canvas .comment-tile .img-wrapper .maximizeButton {
        opacity: 0;
    }

    #canvas .comment-tile .img-wrapper:hover .maximizeButton {
        opacity: 1;
    }
}

#canvas .comment-tile .img-wrapper {
    overflow: hidden;
}

#canvas .tile-controls {
    color: #666;
    font-weight: 100;
}

#canvas .tile-controls a {
    text-align: right;
    cursor: pointer;
}

#canvas .tile-controls a:hover {
    color: #eee;
}

#canvas .tile-controls a:active {
    color: #eee;
}

#canvas .reply-to {
    min-height: 300px;
    overflow: hidden;
}

#canvas .reply-to h6 {
    font-family: sofia-pro, sans-serif;
    /* font-weight: 700; */
    color: white;
    padding: 1rem;
    font-size: 1.2rem;
}

#canvas .reply-to .canvas-tile {
    height: 70%;
}

#canvas .next-steps p {
    font-weight: 200;
    font-size: 0.85rem;
}

#canvas .next-steps .btn {
    font-weight: 200;
    font-size: 0.85rem;
    padding: 0.5rem;
}

#canvas .canvas-sketch {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: transparent;
}

#canvas .quill p {
    font-weight: normal;
    font-size: 1rem;
    padding: 0rem;
}

.ql-editor p {
    color: black;
}

.tag-select{
    border: 1px solid black;
    color: black;
    font-weight: 700;
    text-transform: uppercase;
}

.admin-comment-wrapper{
    background-color: rgba(255,255,255,0.5);
}

.normal-comment-wrapper{
    background-color: rgba(255,255,255,0.5);
}
.option-wrapper .option-img
{
    border-radius: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5rem;
}

.option-wrapper p
{
    text-align: center;
    text-transform: uppercase;
    font-size: 0.8rem;
}

.option-wrapper>div
{
    margin-bottom: 1rem;
    padding: 1rem;
}
a.app-nav,
p.app-nav {
    text-align: center;
    font-size: 1rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
    cursor: pointer;
    /* color: white; */
    font-weight: 700;
    font-family: "sofia-pro";
}

p.cancel {
    font-size: 2rem;
    /* margin-top: 0.8rem; */
    font-weight: 400;
}

div.cancel {
    /* margin-top: 50%; */
    /* transform: translate(0, -50%); */
}

p {
    /* color: white; */
    font-weight: normal;
}

.image {
    background-color: white;
    margin: 20px;
    width: 128px;
    border: 2px solid white;
}

.tag {
    cursor: pointer;
    font-size: 20px;
    padding: 4px 8px;
    font-weight: bold;
    margin: 10px;
}

.inactive {
    /* color: white; */
    background-color: black;
}

.active {
    /* color: black; */
    /* background-color: white; */
}

h1 {
    /* color: white; */
}

h3 {
    /* color: white; */
}

input {
    margin: 30px 0px;
}

label {
    /* color: white; */
}

@font-face {
    font-family: 'rand black italic';
    src: url(/static/media/Rand-Black-Italic.30be897d.otf);
}
@font-face {
    font-family: 'rand black';
    src: url(/static/media/Rand-Black.c01aadca.otf);
}
@font-face {
    font-family: 'rand bold italic';
    src: url(/static/media/Rand-Bold-Italic.75d96d41.otf);
}
@font-face {
    font-family: 'rand bold';
    src: url(/static/media/Rand-Bold.71935b37.otf);
}
@font-face {
    font-family: 'rand heavy italic';
    src: url(/static/media/Rand-Heavy-Italic.e675fe7e.otf);
}
@font-face {
    font-family: 'rand heavy';
    src: url(/static/media/Rand-Heavy.662b8a70.otf);
}

@font-face {
    font-family: 'rand italic';
    src: url(/static/media/Rand-Italic.d2a7e08d.otf);
}
@font-face {
    font-family: 'rand light italic';
    src: url(/static/media/Rand-Light-Italic.3dcc304b.otf);
}
@font-face {
    font-family: 'rand light';
    src: url(/static/media/Rand-Light.32a9d6c4.otf);
}
@font-face {
    font-family: 'rand medium italic';
    src: url(/static/media/Rand-Medium-Italic.8bae44ef.otf);
}
@font-face {
    font-family: 'rand medium';
    src: url(/static/media/Rand-Medium.bde98676.otf);
}
@font-face {
    font-family: 'rand regular';
    src: url(/static/media/Rand-Regular.0adc30dd.otf);
}
@font-face {
    font-family: 'rand thin italic';
    src: url(/static/media/Rand-Thin-Italic.a8dbe588.otf);
}
@font-face {
    font-family: 'rand thin';
    src: url(/static/media/Rand-Thin.13d43f72.otf);
}

p, div, button, span
{
    font-family: 'rand regular';
}

h1, h2, h3
{
    font-family: 'rand heavy';
}

h4, h5, h6
{
    font-family: 'rand bold';
}

.navbar-brand
{
    font-family: 'rand bold';
  
}

.makeStyles-root-1 * {
  font-family: 'rand regular' !important;
}

.main-btn{
    /* margin-top: 1rem; */
    margin-bottom: 1rem;
    border: 2px solid #5714ac;
    padding: 0.5rem 3rem;
    font-size: 0.7rem;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
    /* margin-left: 1rem; */
    cursor: pointer;
    background-color: #ffffff;
    border-radius: 2rem;
    font-size: 1rem;
    color: #5714ac;
  }
  
  .main-btn:hover, .main-btn:active, .main-btn:focus{
    border-color: #5714ac;
    background-color: #5714ac;
    color: #FFFFFF;
    outline: none;
  }

.color-dropdown-toggle
{
    cursor: pointer;
    /* position: absolute; */
    /* margin-top: 50%; */
    /* transform: translate(0, -25%); */
    margin-bottom: 2rem;
    /* margin-top: 1rem; */
}

.tools-links
{
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    position: relative;
}

.tools-links a
{
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    font-weight: 200;
    cursor: pointer;
}

#annotate-tools
{
    margin-bottom: 1rem;
    min-height: 3rem;
    margin-top: 1rem;
    padding-top: 1rem;
}

.annotate-textarea
{
    margin-top: 2rem;
    margin-bottom: 2rem;
}

#annotate-app .btn
{
    margin-top: 0;

}

#annotate-app
{
    padding-bottom: 5rem;
}

section {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-color: #222;
    color: white;
    font-size: 30px;
}

img {
    width: 100px;
}

#instructions-modal .modal-content .modal-header .close:focus{
  outline: none;
}
.custom-switch .custom-control-input:checked~.custom-control-label::after{
  background-color:#000000;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #000000;
  border-color: #a3a3a3;
  background-color: #fff;
}

#profile .user-details-container, #profile .description-container{
  padding-top: 1rem;
  /* padding-left: 6rem;
  padding-right: 6rem; */
  justify-content: flex-start;
}

#profile .user-details-container{
  align-items:center;
}

#profile .description-container{
  align-items: baseline;
}


#profile .user-details-container h3{
  /* font-family: sofia-pro; */
  margin-bottom: 5px;
  word-break: break-word;
}

#profile .user-details-container h4{
  font-weight: 400;
  /* font-size: 22px; */
  color: #666666;
  /* font-family: sofia-pro; */
  margin-bottom: 10px;
  word-break: break-word;
}

#profile .user-details-container h6{
  font-size: 12px;
  /* font-family: sofia-pro; */
  margin-bottom: 20px;
  word-break: break-word;
}

.react-switch-bg{
  border: 1px solid #cccccc;
}

#profile ::-webkit-input-placeholder{
  color:#a3a3a3;
  font-weight: 100;
  font-size: 12px;
  /* font-family: sofia-pro; */
}

#profile :-ms-input-placeholder{
  color:#a3a3a3;
  font-weight: 100;
  font-size: 12px;
  /* font-family: sofia-pro; */
}

#profile ::-ms-input-placeholder{
  color:#a3a3a3;
  font-weight: 100;
  font-size: 12px;
  /* font-family: sofia-pro; */
}

#profile ::placeholder{
  color:#a3a3a3;
  font-weight: 100;
  font-size: 12px;
  /* font-family: sofia-pro; */
}

#profile :-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #a3a3a3;
  font-weight: 100;
  font-size: 12px;
  /* font-family: sofia-pro; */
}

#profile ::-ms-input-placeholder { /* Microsoft Edge */
  color: #a3a3a3;
  font-weight: 100;
  font-size: 12px;
  /* font-family: sofia-pro; */
}

/* for syncloader */
#profile .css-1hve7j5{
  margin:auto;
}

/* Edit name */
#profile .user-details-container .name .edit-name{
  display:none;
}

#profile .user-details-container .name:hover .edit-name{
  display:flex;
}

#profile .user-details-container .name:active .edit-name{
  display:flex;
}

/* edit description */
#profile .description-container .description .edit-description{
  display:none;
}

#profile .description-container .description:hover .edit-description{
  display:flex;
}

#profile .description-container .description:active .edit-description{
  display:flex;
}

/* Edit company */
#profile .user-details-container .company .edit-company{
  display:none;
}

#profile .user-details-container .company:hover .edit-company{
  display:flex;
}

#profile .user-details-container .company:active .edit-company{
  display:flex;
}

/* Edit location */
#profile .user-details-container .location .edit-location{
  display:none;
}

#profile .user-details-container .location:hover .edit-location{
  display:flex;
  padding-left: 15px;
}

#profile .user-details-container .location:active .edit-location{
  display:flex;
  padding-left: 15px;
}

/* small devices like iphone 4 or iphone 5 */
@media (min-width: 300px){
  #profile .user-details-container{
    padding-left: 1rem;
    padding-right: 1rem;
    justify-content:center;
  }
   #profile .description-container{
    padding-left: 1rem;
    padding-right: 1rem;
    justify-content:flex-start;
  }
  #profile .switch-container{
    width:350px;
    justify-content: space-evenly;
  }
  #profile .switch-container .switch-button{
    margin:0;
    color:#FFFFFF;
    padding:0.5rem; 
    font-size:12px;
    text-transform:capitalize; 
    border:none;
    width:80px;
    border-radius:5px; 
    margin-left:10px;
  }
  /* name, company, location container */
  #profile .name-container{
    padding-left: 1rem;
  }
  #profile .name-wrapper{
    /* justify-content: center; */
  }
  #profile .user-details-container h3{
    font-size: 16px;
    margin-bottom:0.2rem;
    margin-top: 0;
  }
  #profile .user-details-container h4{
    font-size: 14px;
    margin-bottom:0.2rem;
  }
  #profile .description-container .description{
    padding-left: 0;
  }
  #profile .switch-container{
    margin-top: 1.5rem;
  }
}

/* Progress Bar */
#progress-bar {
  position: relative;
  width: 100%;
  font-family: 'rand regular';
  font-weight: 400;
  color: rgb(102, 102, 102);
  margin-bottom: 1rem;
}

.progress-stepper-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 100%;
}

.progress-stepper-container span {
  flex: 1 1 auto;
  min-height: 1rem;
  margin-right: .25rem;
}

.missing-progress-container {
  font-size: 14px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 1.5rem 1.5rem;
  border: 1px lightgrey solid;
  position: absolute;
  z-index: 2;
  margin-top: .5rem;
  background: white;
  border-radius: 2rem;
}

.missing-progress-container .details {
  margin-left: 1rem;
  margin-bottom: 0;
}

.missing-progress-container .question-ul {
  list-style-type: '- ';
}

.form-control[readonly] {
    background-color: rgba(233, 232, 232, 0.425);
    opacity: 1;
}

/* usual mobile devices with width more than 340 */
@media (min-width: 340px){
  #profile .user-details-container h3{
    font-size: 20px;
    margin-bottom:0.2rem;
    margin-top: 0;
  }
  #profile .user-details-container h4{
    font-size: 16px;
    margin-bottom:0.2rem;
  }
}

@media (min-width: 600px){
  #profile .user-details-container, #profile .description-container{
    padding-left: 1rem;
    padding-right: 1rem;
    justify-content: flex-start;
  }
  #profile .user-details-container h3{
    font-size: 28px;
    margin-bottom:0.5rem;
    margin-top: 1rem;
  }
  #profile .user-details-container h4{
    font-size: 22px;
    margin-bottom:0.5rem;
  }
  #profile .user-details-container{
    justify-content: center;
  }
  #profile .switch-container{
    width:200px;
    justify-content: flex-end;
    margin-top: 0;
  }
  #profile .switch-container .switch-button{
     width:100px; 
  }
  /* name, company, location container */
  #profile .name-container{
    padding-left: 2rem;
  }
  #profile .name-wrapper{
    justify-content: flex-start;
  }
  #profile .description-container .description{
    padding-left: 2rem;
  }
}

@media (min-width: 1000px){
  #profile .user-details-container, #profile .description-container{
    /* padding-left: 6rem;
    padding-right:6rem; */
  }
  #profile .switch-container{
    width:200px;
  }
}

.user-email-link {
  cursor: pointer;
  color: grey;
}

.user-email-link:hover {
  color: black;
  text-decoration: underline;
}

/* Location searcg */
.location-search-input{
  margin: 0;
  padding: 0.5rem 0.5rem;
  font-size: 1rem;
  width: 100%;
  border: 1px solid #ced4da;
}

.suggestion-item{
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: normal;
  cursor: pointer;
}

.suggestion-item:hover{
  background-color: #f1f1f1;
}

.autocomplete-dropdown-container{
  border: 1px solid #ced4da;
}

.ReactCrop img{
  width: 100%;
}

.crop-btn{
  background-color: #000;
  color: #fff;
  padding: 1rem 3rem;
  border: 1px solid #000;
  border-radius: 5px;
}
.gallery-wrapper
{
    min-height: 50vh;
    max-height: 50vh;
    /* border: 1px dashed grey; */
    background-color: #eee;
    overflow-y: scroll;
}

.media-drop-wrapper{
    background-color: rgb(245, 245, 245);
}

.other-documents-wrapper
{
    display: flex;
    flex-wrap: wrap;
}

.other-documents-item{
    width: 80px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 10px;
}

.gallery-wrapper.selected
{
    min-height: 50vh;
    max-height: 50vh;
    border: none;
    background-color: #fff;
    overflow-x: hidden;
    overflow-y: scroll;
}

button.search-upload-btn
{
    margin-top: 0px;
    border-radius: 0px;
    padding: 0.4rem;
    min-width: 100px;
    width: 100%;
    text-transform: none;
}

.file-input
{
    display: none;
}

.btn label
{
    width: 100%;
    height: 100%;
    margin: 0px;
    cursor: pointer;
}

p.search-or
{
    padding: 0.4rem;
}

.search-upload-option img
{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    max-height: 100%;
    /* max-width: 100%; */
}

div.option
{
    margin-top: 1rem;
    margin-bottom: 1rem;

}

.css-sc2rit{
    margin: auto;
}
/* IMAGES TILE ON PROFILE */
#image-tile-wrapper {
  justify-content: flex-start;
}
#image-tile-wrapper .image-tile{
  min-height: 250px;
  width: 200px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
  border: 1px solid #cccccc;
  margin-bottom: 2rem;
  height: 90%;
  text-align: center;
  display:flex;
  flex-direction: column;
  /* justify-content: flex-end;
  align-items: flex-end; */
  justify-content: center;
}
#image-tile-wrapper .image-tile .img-fluid{
  /* max-height:100%;
  max-height: -moz-available;          
  max-height: -webkit-fill-available; 
  max-height: fill-available;
  flex:1; */
  width: 100%;
  height: auto;
}
#image-tile-wrapper .image-tile .icons-wrapper{
  opacity:0;
  position:absolute;
}
#image-tile-wrapper .image-tile:hover .icons-wrapper{
  opacity:1;
  position:absolute;
}
#image-tile-wrapper .image-tile:active .icons-wrapper{
  opacity:1;
  position:absolute;
}

#image-tile-wrapper .image-tile .document{
  width: 100%;
  width: -moz-available;          /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  width: fill-available;
  margin-bottom: 50%;
}

@media (min-width: 300px){
  #image-tile-wrapper .image-tile{
    height: 70%;
    width: 70%;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  #image-tile-wrapper {
    justify-content: center;
  }
}
@media (min-width: 600px){
  #image-tile-wrapper .image-tile{
    height: 90%;
    width: 200px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  #image-tile-wrapper {
    justify-content: flex-start;
  }
}
@media (min-width: 1000px){
  #image-tile-wrapper .image-tile{
    height: 90%;
  }
}

/* QUESTION MODAL */
#question-modal .modal-header, #question-modal .modal-footer{
  border:none;
}

#question-modal .selected-option{
  background-color: #000000;
  color:#FFFFFF;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  width:120px;
  margin: 5px;
  padding: 10px;
  text-align: center;
  /* font-family: sofia-pro; */
  font-weight: 400;
  border-radius: 5px;
  font-size: 12px;
}

#question-modal .unselected-option{
  background-color: #EBEBEB;
  color:#000000;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  width:120px;
  margin: 5px;
  padding: 10px;
  text-align: center;
  /* font-family: sofia-pro; */
  font-weight: 400;
  border-radius: 5px;
  font-size: 12px;
}

#question-modal .options-container{
  padding-left: 20%;
  padding-right: 20%;
  justify-content: center;
}

#question-modal .MuiSlider-root{
  color:#000000;
}

/* #question-modal .MuiSlider-track{
  background-color:#000000;
}

#question-modal .MuiSlider-rail{
  background-color:#a3a3a3;
}

#question-modal .MuiSlider-thumb{
  background-color:#000000;
}

#question-modal .PrivateValueLabel-circle-49{
  background-color:#000000;
}

#question-modal .PrivateValueLabel-circle-24, #question-modal .PrivateValueLabel-circle-74, #question-modal .PrivateValueLabel-circle-99, #question-modal .PrivateValueLabel-circle-124{
  background-color:#000000;
} */

#question-modal .dimensions-input{
  width:80px;
  text-align:center;
}

@media (min-width: 300px){
  #question-modal .options-container{
    padding-left: 0%;
    padding-right: 0%;
  }

  #question-modal .dimensions-input{
    width:50px;
  }
}

@media (min-width: 450px){
  #question-modal .options-container{
    padding-left: 6%;
    padding-right: 6%;
  }
}

@media (min-width: 600px){
  #question-modal .options-container{
    padding-left: 20%;
    padding-right: 20%;
  }
  #question-modal .dimensions-input{
    width:80px;
  }
}

@media (min-width: 1000px){
  #question-modal .options-container{
    padding-left: 20%;
    padding-right: 20%;
  }
}

/* DISPLAY QUESTIONS IN QUESTION TILES */
#questions .questions-container {
  display: flex;
  width: 100%;
  flex-wrap:wrap;
}
#questions .questions-container .question-tile-container {
  justify-content: space-between;
  margin:0.5rem;
  display: flex;
  flex-direction: column;
}

#questions .questions-container .question-tile-container .question-tile{
  display: flex;
  flex-direction: column;
  border: 1px solid;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  flex:1 1;
  width: 200px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
  border: 1px solid #cccccc;
  min-height:250px;
  text-align: center;
}

#questions .questions-container .question-tile-container .edit-question{
  opacity:0;
}

#questions .questions-container .question-tile-container:hover .edit-question{
  height:20px;
  text-align: right;
  cursor:pointer;
  opacity:1;
}

#questions .questions-container .question-tile-container:active .edit-question{
  height:20px;
  text-align: right;
  cursor:pointer;
  opacity:1;
}

#questions .questions-container .question-title{
  font-size: 14px;
  /* font-family: sofia-pro; */
  font-weight: 400;
}

#questions .questions-container .add-question, #questions .add-question{
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100px;
  font-family: sofia-rpo;
  margin-top: -40px;
  cursor: pointer;
}

#questions .questions-container .options-container{
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  justify-content: center;
  flex:1 1;
}

#questions .questions-container .unselected-option{
  background-color: #EBEBEB;
  color: #000000;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  margin: 5px;
  width: 44%;
  /* font-family: sofia-pro; */
  font-weight: 400;
  font-size: 10px;
  padding: 0.2rem;
  word-break: break-word;
  text-align: center;
}

@media (min-width: 300px){
  #questions .questions-container{
    justify-content: center;
  }
  #questions .questions-container .question-tile-container .question-tile{
    width: 100%;
  }
  #questions .questions-container .question-tile-container{
    width: 90%;
  }
}

@media (min-width: 600px){
  #questions .questions-container{
    justify-content: flex-start;
  }
  #questions .questions-container .question-tile-container .question-tile{
    width: 200px;
  }
  #questions .questions-container .question-tile-container{
    width: auto;
  }
}
.provider-agreement {
  background-color: #ffffff;
  max-width: 451.3pt;
  padding: 72pt 72pt 72pt 72pt; }
  .provider-agreement ol.lst-kix_list_1-3 {
    list-style-type: none; }
  .provider-agreement ol.lst-kix_list_1-4 {
    list-style-type: none; }
  .provider-agreement .lst-kix_list_2-6 > li:before {
    content: "" counter(lst-ctn-kix_list_2-6, decimal) ". "; }
  .provider-agreement .lst-kix_list_2-7 > li:before {
    content: "" counter(lst-ctn-kix_list_2-7, lower-latin) ". "; }
  .provider-agreement .lst-kix_list_2-7 > li {
    counter-increment: lst-ctn-kix_list_2-7; }
  .provider-agreement ol.lst-kix_list_1-5 {
    list-style-type: none; }
  .provider-agreement ol.lst-kix_list_1-6 {
    list-style-type: none; }
  .provider-agreement .lst-kix_list_2-1 > li {
    counter-increment: lst-ctn-kix_list_2-1; }
  .provider-agreement ol.lst-kix_list_1-0 {
    list-style-type: none; }
  .provider-agreement .lst-kix_list_2-4 > li:before {
    content: "" counter(lst-ctn-kix_list_2-4, lower-latin) ". "; }
  .provider-agreement .lst-kix_list_2-5 > li:before {
    content: "" counter(lst-ctn-kix_list_2-5, lower-roman) ". "; }
  .provider-agreement .lst-kix_list_2-8 > li:before {
    content: "" counter(lst-ctn-kix_list_2-8, lower-roman) ". "; }
  .provider-agreement ol.lst-kix_list_1-1 {
    list-style-type: none; }
  .provider-agreement ol.lst-kix_list_1-2 {
    list-style-type: none; }
  .provider-agreement .lst-kix_list_1-1 > li {
    counter-increment: lst-ctn-kix_list_1-1; }
  .provider-agreement ol.lst-kix_list_2-6.start {
    counter-reset: lst-ctn-kix_list_2-6 0; }
  .provider-agreement ol.lst-kix_list_1-8.start {
    counter-reset: lst-ctn-kix_list_1-8 0; }
  .provider-agreement ol.lst-kix_list_2-3.start {
    counter-reset: lst-ctn-kix_list_2-3 0; }
  .provider-agreement ol.lst-kix_list_1-5.start {
    counter-reset: lst-ctn-kix_list_1-5 0; }
  .provider-agreement ol.lst-kix_list_1-7 {
    list-style-type: none; }
  .provider-agreement .lst-kix_list_1-7 > li {
    counter-increment: lst-ctn-kix_list_1-7; }
  .provider-agreement ol.lst-kix_list_1-8 {
    list-style-type: none; }
  .provider-agreement ol.lst-kix_list_2-5.start {
    counter-reset: lst-ctn-kix_list_2-5 0; }
  .provider-agreement .lst-kix_list_2-0 > li {
    counter-increment: lst-ctn-kix_list_2-0; }
  .provider-agreement .lst-kix_list_2-3 > li {
    counter-increment: lst-ctn-kix_list_2-3; }
  .provider-agreement .lst-kix_list_2-6 > li {
    counter-increment: lst-ctn-kix_list_2-6; }
  .provider-agreement ol.lst-kix_list_1-7.start {
    counter-reset: lst-ctn-kix_list_1-7 0; }
  .provider-agreement .lst-kix_list_1-2 > li {
    counter-increment: lst-ctn-kix_list_1-2; }
  .provider-agreement ol.lst-kix_list_2-2.start {
    counter-reset: lst-ctn-kix_list_2-2 0; }
  .provider-agreement .lst-kix_list_1-5 > li {
    counter-increment: lst-ctn-kix_list_1-5; }
  .provider-agreement .lst-kix_list_1-8 > li {
    counter-increment: lst-ctn-kix_list_1-8; }
  .provider-agreement ol.lst-kix_list_1-4.start {
    counter-reset: lst-ctn-kix_list_1-4 0; }
  .provider-agreement ol.lst-kix_list_1-1.start {
    counter-reset: lst-ctn-kix_list_1-1 0; }
  .provider-agreement ol.lst-kix_list_2-2 {
    list-style-type: none; }
  .provider-agreement ol.lst-kix_list_2-3 {
    list-style-type: none; }
  .provider-agreement ol.lst-kix_list_2-4 {
    list-style-type: none; }
  .provider-agreement ol.lst-kix_list_2-5 {
    list-style-type: none; }
  .provider-agreement .lst-kix_list_1-4 > li {
    counter-increment: lst-ctn-kix_list_1-4; }
  .provider-agreement ol.lst-kix_list_2-0 {
    list-style-type: none; }
  .provider-agreement .lst-kix_list_2-4 > li {
    counter-increment: lst-ctn-kix_list_2-4; }
  .provider-agreement ol.lst-kix_list_1-6.start {
    counter-reset: lst-ctn-kix_list_1-6 0; }
  .provider-agreement ol.lst-kix_list_2-1 {
    list-style-type: none; }
  .provider-agreement ol.lst-kix_list_1-3.start {
    counter-reset: lst-ctn-kix_list_1-3 0; }
  .provider-agreement ol.lst-kix_list_2-8.start {
    counter-reset: lst-ctn-kix_list_2-8 0; }
  .provider-agreement ol.lst-kix_list_1-2.start {
    counter-reset: lst-ctn-kix_list_1-2 0; }
  .provider-agreement .lst-kix_list_1-0 > li:before {
    content: "(" counter(lst-ctn-kix_list_1-0, decimal) ") "; }
  .provider-agreement ol.lst-kix_list_2-6 {
    list-style-type: none; }
  .provider-agreement .lst-kix_list_1-1 > li:before {
    content: "" counter(lst-ctn-kix_list_1-1, lower-latin) ". "; }
  .provider-agreement .lst-kix_list_1-2 > li:before {
    content: "" counter(lst-ctn-kix_list_1-2, lower-roman) ". "; }
  .provider-agreement ol.lst-kix_list_2-0.start {
    counter-reset: lst-ctn-kix_list_2-0 0; }
  .provider-agreement ol.lst-kix_list_2-7 {
    list-style-type: none; }
  .provider-agreement ol.lst-kix_list_2-8 {
    list-style-type: none; }
  .provider-agreement .lst-kix_list_1-3 > li:before {
    content: "" counter(lst-ctn-kix_list_1-3, decimal) ". "; }
  .provider-agreement .lst-kix_list_1-4 > li:before {
    content: "" counter(lst-ctn-kix_list_1-4, lower-latin) ". "; }
  .provider-agreement ol.lst-kix_list_1-0.start {
    counter-reset: lst-ctn-kix_list_1-0 0; }
  .provider-agreement .lst-kix_list_1-0 > li {
    counter-increment: lst-ctn-kix_list_1-0; }
  .provider-agreement .lst-kix_list_1-6 > li {
    counter-increment: lst-ctn-kix_list_1-6; }
  .provider-agreement .lst-kix_list_1-7 > li:before {
    content: "" counter(lst-ctn-kix_list_1-7, lower-latin) ". "; }
  .provider-agreement ol.lst-kix_list_2-7.start {
    counter-reset: lst-ctn-kix_list_2-7 0; }
  .provider-agreement .lst-kix_list_1-3 > li {
    counter-increment: lst-ctn-kix_list_1-3; }
  .provider-agreement .lst-kix_list_1-5 > li:before {
    content: "" counter(lst-ctn-kix_list_1-5, lower-roman) ". "; }
  .provider-agreement .lst-kix_list_1-6 > li:before {
    content: "" counter(lst-ctn-kix_list_1-6, decimal) ". "; }
  .provider-agreement li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt; }
  .provider-agreement .lst-kix_list_2-0 > li:before {
    content: "" counter(lst-ctn-kix_list_2-0, decimal) ". "; }
  .provider-agreement .lst-kix_list_2-1 > li:before {
    content: "" counter(lst-ctn-kix_list_2-1, lower-latin) ". "; }
  .provider-agreement ol.lst-kix_list_2-1.start {
    counter-reset: lst-ctn-kix_list_2-1 0; }
  .provider-agreement .lst-kix_list_2-5 > li {
    counter-increment: lst-ctn-kix_list_2-5; }
  .provider-agreement .lst-kix_list_2-8 > li {
    counter-increment: lst-ctn-kix_list_2-8; }
  .provider-agreement .lst-kix_list_1-8 > li:before {
    content: "" counter(lst-ctn-kix_list_1-8, lower-roman) ". "; }
  .provider-agreement .lst-kix_list_2-2 > li:before {
    content: "" counter(lst-ctn-kix_list_2-2, lower-roman) ". "; }
  .provider-agreement .lst-kix_list_2-3 > li:before {
    content: "" counter(lst-ctn-kix_list_2-3, decimal) ". "; }
  .provider-agreement .lst-kix_list_2-2 > li {
    counter-increment: lst-ctn-kix_list_2-2; }
  .provider-agreement ol.lst-kix_list_2-4.start {
    counter-reset: lst-ctn-kix_list_2-4 0; }
  .provider-agreement ol {
    margin: 0;
    padding: 0; }
  .provider-agreement table td,
  .provider-agreement table th {
    padding: 0; }
  .provider-agreement .c14 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #000000;
    border-top-width: 0pt;
    border-right-width: 0pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 0pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 0pt;
    width: 225pt;
    border-top-color: #000000;
    border-bottom-style: solid; }
  .provider-agreement .c20 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #000000;
    border-top-width: 0pt;
    border-right-width: 0pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 0pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 0pt;
    width: 229.5pt;
    border-top-color: #000000;
    border-bottom-style: solid; }
  .provider-agreement .c24 {
    margin-left: 72pt;
    padding-top: 0pt;
    text-indent: -72pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify;
    margin-right: 14.4pt; }
  .provider-agreement .c3 {
    background-color: #ffffff;
    margin-left: 18pt;
    padding-top: 0pt;
    padding-bottom: 7.5pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify;
    margin-right: -3.2pt; }
  .provider-agreement .c1 {
    margin-left: 14.4pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left;
    margin-right: 14.4pt;
    height: 11pt; }
  .provider-agreement .c0 {
    background-color: #ffffff;
    margin-left: 18pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify; }
  .provider-agreement .c34 {
    margin-left: 18pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0666666666666667;
    orphans: 2;
    widows: 2;
    text-align: justify;
    margin-right: -3.2pt; }
  .provider-agreement .c23 {
    margin-left: 14.4pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify;
    margin-right: 14.4pt; }
  .provider-agreement .c16 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0666666666666667;
    orphans: 2;
    widows: 2;
    text-align: left; }
  .provider-agreement .c25 {
    margin-left: 18pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0666666666666667;
    orphans: 2;
    widows: 2;
    text-align: justify;
    margin-right: 14.4pt; }
  .provider-agreement .c12 {
    margin-left: 18pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0666666666666667;
    orphans: 2;
    widows: 2;
    text-align: justify;
    margin-right: -7.7pt; }
  .provider-agreement .c4 {
    -webkit-text-decoration-skip: none;
    color: #000000;
    font-weight: 400;
    text-decoration: underline;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
    font-size: 12pt;
    font-family: "Times New Roman"; }
  .provider-agreement .c2 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Times New Roman";
    font-style: normal; }
  .provider-agreement .c27 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify;
    margin-right: 14.4pt; }
  .provider-agreement .c10 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify; }
  .provider-agreement .c21 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: center; }
  .provider-agreement .c33 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify; }
  .provider-agreement .c18 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0666666666666667;
    orphans: 2;
    widows: 2;
    text-align: left; }
  .provider-agreement .c37 {
    margin-left: -5.4pt;
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto; }
  .provider-agreement .c30 {
    -webkit-text-decoration-skip: none;
    text-decoration: underline;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none; }
  .provider-agreement .c9 {
    font-size: 12pt;
    font-family: "Times New Roman";
    font-weight: 700; }
  .provider-agreement .c39 {
    font-weight: 400;
    font-size: 11pt;
    font-family: "Calibri"; }
  .provider-agreement .c15 {
    font-weight: 400;
    font-size: 12pt;
    font-family: "Calibri"; }
  .provider-agreement .c35 {
    font-weight: 400;
    font-size: 11pt;
    font-family: "Times New Roman"; }
  .provider-agreement .c5 {
    font-size: 12pt;
    font-family: "Times New Roman";
    font-weight: 400; }
  .provider-agreement .c40 {
    margin-left: 14.4pt;
    margin-right: 1.3pt; }
  .provider-agreement .c42 {
    margin-left: 14.4pt;
    margin-right: 14.4pt; }
  .provider-agreement .c38 {
    max-width: 451.3pt;
    padding: 72pt 72pt 72pt 72pt; }
  .provider-agreement .c22 {
    padding: 0;
    margin: 0; }
  .provider-agreement .c28 {
    margin-left: 36pt;
    text-indent: -36pt; }
  .provider-agreement .c7 {
    vertical-align: baseline;
    font-style: normal; }
  .provider-agreement .c13 {
    vertical-align: baseline;
    font-style: italic; }
  .provider-agreement .c32 {
    height: 7.2pt; }
  .provider-agreement .c41 {
    margin-right: 1.3pt; }
  .provider-agreement .c29 {
    height: 10.8pt; }
  .provider-agreement .c26 {
    height: 0pt; }
  .provider-agreement .c11 {
    text-decoration: none; }
  .provider-agreement .c31 {
    background-color: #ffff00; }
  .provider-agreement .c6 {
    color: #000000; }
  .provider-agreement .c8 {
    height: 11pt; }
  .provider-agreement .c17 {
    background-color: #ffffff; }
  .provider-agreement .c36 {
    color: #555555; }
  .provider-agreement .c19 {
    padding-left: 0pt; }
  .provider-agreement .title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left; }
  .provider-agreement .subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left; }
  .provider-agreement li {
    color: #000000;
    font-size: 11pt;
    font-family: "Calibri"; }
  .provider-agreement p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Calibri"; }
  .provider-agreement h1 {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 24pt;
    padding-bottom: 6pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left; }
  .provider-agreement h2 {
    padding-top: 18pt;
    color: #000000;
    font-weight: 700;
    font-size: 18pt;
    padding-bottom: 4pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left; }
  .provider-agreement h3 {
    padding-top: 14pt;
    color: #000000;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Calibri";
    line-height: 1.0666666666666667;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left; }
  .provider-agreement h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0666666666666667;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left; }
  .provider-agreement h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0666666666666667;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left; }
  .provider-agreement h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0666666666666667;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left; }

#splash-auth {
  position: relative;
  z-index: 0;
  top: 0;
  left: 0;
  height: 100%;
  min-height: 100vh;
  width: 100vw;
  background: white;
  padding: 4rem 20%; }

.spinner-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }

.spinner-container .spinner-border-lg {
  height: 4rem;
  width: 4rem;
  border-width: .3rem;
  margin-bottom: 1rem; }

.tos-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  text-align: center; }
  .tos-container h2:first-of-type {
    color: var(--theme-color-1); }

.tos-container p .bold {
  font-weight: bold; }

.tos-button {
  display: block;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  margin: 0;
  min-width: 120px;
  padding: .5rem .2rem;
  background-color: #999;
  font-weight: 700;
  border: none;
  color: #fff;
  line-height: 1.5;
  font-size: 1rem;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out; }

.cap {
  text-transform: capitalize; }

.tos-button:hover {
  background-color: #000; }

.tos-button:disabled {
  background-color: #999; }

.main-btn[disabled] {
  cursor: auto;
  cursor: initial;
  color: var(--theme-color-2);
  border-color: var(--theme-color-2); }
  .main-btn[disabled]:hover {
    cursor: auto;
    cursor: initial;
    background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
    background: initial;
    color: var(--theme-color-2); }

#splash-modal_container {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.699);
  display: flex;
  justify-content: center;
  align-items: center; }

/* Modal Container */
.splash-modal_body {
  height: 100%;
  width: 100%;
  max-height: 900px;
  max-width: 768px;
  background: white;
  padding: 2rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto; }

/* Modal Title */
.splash-modal_body div[name="title"] .logo_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content; }
  .splash-modal_body div[name="title"] .logo_container h2 {
    text-transform: uppercase;
    color: var(--theme-color-1);
    margin-bottom: 1rem; }

/* Modal Body */
.splash-modal_body div[name="body"] {
  min-height: 350px;
  max-height: 500px;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  flex-grow: 1;
  overflow-y: auto; }

/* Modal Footer */
.splash-modal_body div[name='footer'] {
  display: flex;
  flex-direction: column; }
  .splash-modal_body div[name='footer'] .checkbox_container {
    margin: 1rem auto; }
    .splash-modal_body div[name='footer'] .checkbox_container input, .splash-modal_body div[name='footer'] .checkbox_container label {
      margin: 0; }
  .splash-modal_body div[name='footer'] .main-btn_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .splash-modal_body div[name='footer'] .main-btn_container span {
      font-size: 12px;
      color: darkgrey;
      cursor: pointer; }
      .splash-modal_body div[name='footer'] .main-btn_container span:hover {
        color: black;
        text-decoration: underline; }




#navbar-dark, #navbar-light
{
  /* font-family: sofia-pro, sans-serif; */
  font-weight: 400;
  padding: 0px;
  padding-left: 2rem;
  padding-right: 2rem;
}

#navbar-dark .nav-link, #navbar-light .nav-link
{
  /* font-family: sofia-pro, sans-serif; */
  font-size: 0.75rem;
  font-weight: 400;
  padding-top: 0.5rem; 
  padding-bottom: 0.5rem;
}


.nav-link:focus,.nav-link:focus
{
  outline: none;
  box-shadow: none;
}

#navbar-dark .navbar-brand, #navbar-light .navbar-brand
{
  /* font-family: bigmoore, serif; */
  font-size: 1.7rem;
  font-weight: 600;
  padding-top: 0rem;
  padding-bottom: 0rem;

}

#navbar-dark
{
  background: black;
}


#navbar-light
{
  background: transparent;
}

#navbar-dark .nav-link, #navbar-dark .navbar-brand
{
  color: white;
}


#navbar-light .nav-link, #navbar-light .navbar-brand
{
  color: white;
}
/************************************************************************************/
/************************************************************************************/

/* HERO */
#hero{
  background-position : bottom; /* Center the image */
  background-repeat   : no-repeat; /* Do not repeat the image */
  background-size     : cover; /* Resize the background image to cover the entire container */
  min-height          : 100vh;
  font-family         : sofia-pro, sans-serif;
  display: flex;

}

#hero h1 {
color           :#FFFFFF;
line-height     : 1.5rem;
font-size       : 1.7rem;
font-weight     : bold;
max-width       : 486px;
padding-bottom  : 1rem;
margin-bottom   : 1rem;
}
#hero h6{
color           :#FFFFFF;
font-size       : 0.9rem;
font-weight     : 400;
padding-bottom  : 1rem;
line-height     : 1.3rem;
}

#hero .btn{
  background-color    : #FFFFFF;
  box-shadow          : 2px 2px 6px rgba(0, 0, 0, 0.16);
  color               : #000000;
  border              : none;
  border-radius       : 0.25rem;
  
  margin-left         : 0;
  margin-top          : 2rem;
  padding-top         : 0.8rem;
  padding-bottom      : 0.8rem;
  padding-left        : 2.5rem;
  padding-right       : 2.5rem;
  
  font-size           : 0.95rem;
  font-weight         : 600;
  box-shadow          : 2px 2px 6px rgba(0,0,0,.16);
  min-width           : 15%;
  text-transform: none;

}

#hero .container
{
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  top: -15%;
position: relative;
}

#hero .container-fluid
{
  margin-top    : auto;
  margin-bottom : auto;
  margin-left   : auto;
  margin-right  : auto;
  display       : block;

}

@media (min-width: 300px){
#hero .container-fluid
{
  margin-top: 50%;
}

#hero .container
{
  margin-top: 50%;
}

  #hero h1{
      /* font-size : 40px; */
      max-width : 100%;
      text-align: center;
  }
  #hero h6{
      font-size : 0.9rem;
      font-weight: 400;
      text-align: center;
  }

  #hero .btn{
      margin-left   : auto;
      margin-right  : auto;
      display: block;

  }
}


@media (min-width: 768px){
  #hero .container
  {
    margin-top: 20%;
  }

  #hero .container-fluid
  {
    margin-top: 20%;
  }
  #hero h1{
      font-size     : 2.3rem;
      max-width     : 100%;
  }
  #hero h6{
      font-size: 1rem;
  }

  #hero .btn{
      font-size: 1rem;
  }
}

@media (min-width: 1000px){
#hero .container
{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: unset;
    margin-right: unset;
}

#hero .container-fluid
{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: unset;
    margin-right: unset;
}
  #hero h1 {
      color             :#FFFFFF;
      line-height       : 64px;
      font-size         : 3.3rem;
      font-weight       : bold;
      max-width         : 486px;
      text-align        : left;
  }

  #hero h6 {
      font-size           : 1.2rem;
      text-align          : left;
      /* max-width       : 38rem; */
      padding-left    : 0;
      line-height     : 1.8rem;
    }
      
  #hero .btn{
    margin-top        : 2rem;
    margin-left: 0;
      font-size: 1.2rem;
  }
}

/************************************************************************************/
/************************************************************************************/

/* TEXT_ICON_SECTION */
.text-icon-section{
  padding-top         : 40px;
  padding-bottom      : 40px;
  /* min-height          :100vh; */
  background-color    : #EFEFEF;
  font-family         : sofia-pro, sans-serif;
}

.text-icon-section .container{
  /* min-height      : 100vh; */
  display         : flex;
  flex-direction  : column;
  justify-content : center;
}

.text-icon-section .item-container{
 display          : flex;
 justify-content  : space-between;
 width            : 100%;    
 flex-wrap        : wrap;
}

.text-icon-section .item{
 max-width        : 100%;
 text-align       : center;
 height           : -webkit-fit-content;
 height           : -moz-fit-content;
 height           : fit-content;
 /* margin           : 1rem; */
 padding          : 1rem;
}

.text-icon-section img{
  padding          : 0rem;
  width: unset;

 }

.text-icon-section h2{
  font-family         : sofia-pro, sans-serif;
  text-align       : center;
  font-size        : 1.6rem;
  margin-bottom    : 70px;
  font-weight      : bold;
}

.text-icon-section h4{
 font-size        : 1.1rem;
 font-weight      : bold;
 margin-top       : 40px;

}

.text-icon-section p{
 font-size        : 0.9rem;
 line-height      : 20px;
 font-weight      : normal;
}

@media (min-width: 600px){


  .text-icon-section h4{
      font-size        : 1.2rem;
      margin-bottom    : 0rem;
  }

  .text-icon-section p{
      font-size        : 1rem;
      margin-top       : 0px;
      line-height      : normal;
      font-weight      : 400;

     }
}
@media (min-width: 1000px){
 
  .text-icon-section h2{
      font-family      : sofia-pro, sans-serif;
      text-align       : center;
      font-size        : 2rem;
      margin-bottom    : 70px;
      font-weight      : bold;
  }

  .text-icon-section h4{
      font-size        : 1.2rem;
      margin-bottom    : 0rem;
  }

  .text-icon-section p{
      font-size        : 1rem;
      margin-top       : 0px;
      line-height      : normal;
      font-weight      : 400;
  }

  .text-icon-section img{
    /* padding          : 1rem; */
   }
}


/************************************************************************************/
/************************************************************************************/

/* TESTIMONIALS */
#testimonials
{
  min-height  : 100vh;
  /* font: normal 400 1em sofia-pro, sans-serif;; */
  text-align  : center;
}

#testimonials .container{
  min-height      : 100vh;
  display         : flex;
  flex-direction  : column;
  justify-content : center;
}


#testimonials .container-fluid{
  min-height      : 100vh;
  display         : flex;
  flex-direction  : column;
  justify-content : center;
}

#testimonials h3{
  font-family     : sofia-pro, sans-serif;
  /* font-style      : italic; */
  font-weight     : normal;
  font-size       : 1.7rem;
  line-height     : 43px;
}

#testimonials h3::before{
  font-family     : bureau-grot, sans-serif;
}

#testimonials h3::before {
  content: "\201D";
  margin-right: 0.5rem;
  padding-top: 1rem;
  transform: rotate(180deg) translateY(0);
  display: inline-block;
  color: #434267;
  font-family: bureau-grot, sans-serif;
  font-size: 3rem;
  line-height: 0;
  z-index: 0;
  
}

#testimonials h3::after {
  content: "\201D";
  position: absolute;
  margin-left: 10px;
  transform: translateY(50px);
  display: inline-block;
  color: #434267;
  font-family: bureau-grot, sans-serif;
  font-size: 3rem;
  line-height: 0;
  margin-top: -0.5rem;
  
}

@media (min-width: 300px){
  #testimonials h3{
      font-family     : sofia-pro, sans-serif;
      /* font-style    : italic; */
      font-weight   : normal;
      font-size     : 1.7rem;
      line-height   : 43px;
  }
}

@media (min-width: 600px){
 
  #testimonials h3{
      font-family     : sofia-pro, sans-serif;
      /* font-style    : italic; */
      font-weight   : normal;
      font-size     : 2.5rem;
      line-height   : 3.5rem;
  }
}

/************************************************************************************/
/************************************************************************************/


/* FULL_WIDTH_IMAGE */
.full-width-image{
  background-position     : bottom; /* Center the image */
  background-repeat       : no-repeat; /* Do not repeat the image */
  background-size         : cover; /* Resize the background image to cover the entire container */
  min-height              : 100vh;
  font-family             : "sofia-pro";
  
}

.full-width-image .section4-container{
  text-align          : center;
  padding-top         : 30px;
  background-color    : rgba(0,0,0,0.5);
  min-height          : 100vh;
  padding-left        : 10px;
  padding-right       : 10px;
}

.full-width-image h1{
  color           :#FFFFFF;
  font-weight     : bold;
  margin-bottom   : 1.5rem;
  font-size       : 0.9rem;
}

.full-width-image h6{
  color       :#FFFFFF;
  max-width   : 80%;
  margin      : auto;
  font-style  : normal;
  font-weight : 500;
  font-size   : 24px;
  line-height : 143.5%;
  /* or 34px */

  text-align  : center;
}

@media (min-width: 300px){


  .full-width-image h1{
      font-size       : 1.6rem;
  }
  .full-width-image h6{
    font-size: 0.9rem;
  }
}


@media (min-width: 600px){


  .full-width-image h1{
      font-size       : 1.6rem;
  }
  .full-width-image h6{
    font-size: 1rem;
  }
}

@media (min-width: 1000px){
  
  .full-width-image h6{
    max-width   : 40%;
  }
  .full-width-image h1{
    font-size: 2rem;
  }
  .full-width-image h6{
    font-size: 1rem;
  }
}

/************************************************************************************/
/************************************************************************************/
/* Footer */
#footer{
  background-position     : bottom; /* Center the image */
  background-repeat       : no-repeat; /* Do not repeat the image */
  background-size         : cover; /* Resize the background image to cover the entire container */
  min-height              : 100vh;
  font-family             : "sofia-pro";
}

#footer .container{
  text-align              : center;
  justify-content         : flex-start;
  padding-top             : 30px;
}

#footer .btn{
  background-color        : #FFFFFF;
  color                   : #000000;
  border                  : none;
  border-radius           : 0;
  margin-top              : 30px;
  text-transform          : capitalize;
  padding-top             :1rem;
  padding                 : bottom 1rem;
  font-size               : 20px;
}

#footer h1{
  font-size       : 64px;
  font-weight     : bold;
  color           : #FFFFFF;
  margin-top      : 50px;
  margin-top      : 72px;
  margin-bottom   : 35px;
}


@media (min-width: 300px){

  #footer h1{
    font-size: 2rem;
  }
}

@media (min-width: 600px){


  #footer h1{
    font-size: 3rem;
  }
}

@media (min-width: 1000px){

  #footer h1{
    font-size: 64px;
  }
}

/************************************************************************************/
/************************************************************************************/

/* FOOTER */
footer
{
font: normal 400 1em sofia-pro, sans-serif;;
padding-top: 3rem;
padding-bottom: 3rem;
background-color: #F6F6F6;
font-weight: 400;
}

footer .contact
{
margin-top: 1em;
}

footer a, a:hover
{
color: black;
}


footer .links ul
{
font: normal 400 1em sofia-pro, sans-serif;;
list-style: none;
padding-left: 0px;

}

footer .links ul li:first-of-type
{
font-weight: 900;
font-size: 1.1rem;
margin-bottom: 1rem;
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 

}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
footer.col-12{
  padding-left: 5rem;
  padding-right: 5rem;
} 
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
footer{
  padding-top: 5rem;
  padding-bottom: 5rem;
} 
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
footer{
  padding-top: 5rem;
  padding-bottom: 5rem;
} 

}

/* // 2K or 4K (tablets, 768px and up) */
@media (min-width: 2500px) { 

footer
{
  font-size: 1.2rem;
}

footer .links ul li:first-of-type
{
  font-weight: 900;
  font-size: 1.4rem;
  margin-bottom: 1rem;
}
}

/************************************************************************************/
/************************************************************************************/
/* CTA */

.section-cta button
{
width: 100%;
background-color: white;
padding: 0.8em;
font: normal 400 1em sofia-pro, sans-serif;
font-size: 1.2rem;
font-weight: 600;
color: black;
border: none;
box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.16);
margin-top: 4rem;
margin-bottom: 4rem;

}

.section-cta
{
  background-color: #E7E7E7;
}
/************************************************************************************/
/************************************************************************************/
/* HERO */
h1{
  /* font-family: sofia-pro, sans-serif; */
}

#hero.ecosystem{
  background-image: url(/static/media/ecoweb.6037d568.png);
  }

/* FULL_WIDTH_IMAGE */
.full-width-image.calling-all-creators{
  background-image: url(/static/media/Maker_1920.b226b7d7.jpg);

}
  
/* Footer */
#footer.ecosystem{
  background-image: url(/static/media/ecosystem_phone.1e67cb06.png);
}


@media (min-width: 300px){
    #hero.ecosystem{
      background-image: url(/static/media/ecomobile.50295d32.png);
    }

    .full-width-image.calling-all-creators{
      background-image: none;
    }
    #footer.ecosystem{
      background-image: url(/static/media/ecosystem_phone.1e67cb06.png);
    }
  }

  
  @media (min-width: 600px){
    #hero.ecosystem{
      background-image: url(/static/media/ecotablet.4c8fca5f.png);
    }

    .full-width-image.calling-all-creators{
      background-image: url(/static/media/Maker_1024.c37e3643.jpg);
    }
    #footer.ecosystem{
      background-image: url(/static/media/ecosystem_tab.44b65965.png);
    }
  }
  
  @media (min-width: 1000px){
    #hero.ecosystem{
      background-image: url(/static/media/ecoweb.6037d568.png);
    }

    .full-width-image.calling-all-creators{
      background-image: url(/static/media/Maker_1920.b226b7d7.jpg);
    }
    #footer.ecosystem{
      background-image: url(/static/media/ecosystem_web.f316808b.png);
    }
  }


/************************************************************************************/
/************************************************************************************/

  
  
#basic-info-container{
  font-family: sofia-pro;
}

#basic-info-container .form-label{
  font-weight: 400;
}

#basic-info-container .button-container{
  width:100%;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 1rem;
}

#basic-info-container textarea{
  height: 100px;
}

#basic-info-container h2{
  width:100%;
  text-align: center;
  font-family: sofia-pro;
  font-weight: 400;
  padding-top: 1rem;
}

#basic-info-container .form-group{
  padding:0;
}

#basic-info-container .form-control{
  border-radius: 5px;
}

.image-upload>input {
  display: none;
}

#basic-info-container ::-webkit-input-placeholder{
  color:#a3a3a3;
  font-weight: 100;
  font-size: 14px;
}

#basic-info-container :-ms-input-placeholder{
  color:#a3a3a3;
  font-weight: 100;
  font-size: 14px;
}

#basic-info-container ::-ms-input-placeholder{
  color:#a3a3a3;
  font-weight: 100;
  font-size: 14px;
}

#basic-info-container ::placeholder{
  color:#a3a3a3;
  font-weight: 100;
  font-size: 14px;
}

#basic-info-container :-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #a3a3a3;
  font-weight: 100;
  font-size: 14px;
}

#basic-info-container ::-ms-input-placeholder { /* Microsoft Edge */
  color: #a3a3a3;
  font-weight: 100;
  font-size: 14px;
}

@media (min-width: 300px){
  #basic-info-container{
    padding-left: 1rem;
    padding-right: 1rem;
  }
  #basic-info-container .button-container{
    width:100%;
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 600px){
  #basic-info-container{
    padding-left: 1rem;
    padding-right: 1rem;
  }
  #basic-info-container .button-container{
    width:100%;
    display: flex;
    justify-content: flex-end;
  }
}

@media (min-width: 1000px){
  #basic-info-container{
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

.PhoneInputInput{
  margin: 0;
  padding: 0.5rem 1.3rem;
  font-size: 1rem;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ced4da;
}

.PhoneInput{
  width: 100%;
}
body {
    font-family: sofia-pro, sans-serif;
    /* background-color: #dddddd; */
}

#account {
    /* color: white; */
}

/* #account .navbar-brand {
    font-family: bigmoore, serif;
    font-size: 2rem;
    text-align: center;
    width: 100%;
}

#account .navbar {
    margin-bottom: 5rem;
    background-color: transparent !important; */
/* } */

#account h2 {
    text-align: center;
    /* font-size: 1.2rem; */
    /* font-weight: 700; */
    margin-bottom: 2rem;
}

#account .sign-out-btn {
    margin-bottom: 2rem;
    margin-top: 2rem;
    background-color: white;
    color: black;
    border: 1px solid black;
}

#account h2:hover img {
    display: inline-block;
}

#account .widget-name:hover img {
    display: inline-block;
}

#account h2 input {
    border: none;
    border-bottom: 1px solid black;
    width: 10rem;
    border-radius: 0px;
    display: inline-block;
}

#account .widget-name input {
    border: none;
    font-size: 1.25rem;
    border-bottom: 1px solid black;
    width: 10rem;
    border-radius: 0px;
    display: inline-block;
    text-align: center;
}

#account h2 img {
    height: 1.5rem;
    display: none;
    margin-left: 0.5rem;
}

.canvas-widget .widget-name img {
    height: 1.5rem;
    display: none;
    margin-left: 0.5rem;
}

.canvas-widget .widget-name h5 {
    height: 1.5rem;
    font-size: 1.5rem;
    font-weight: 700;
}

#profile-pic-form {
    visibility: hidden;
    position: absolute;
}

#account .profile-pic-wrapper img {
    border-radius: 10rem;
}

#account h6 {
    font-size: 1.2rem;
    text-align: center;
}

#account .canvas-widget {
    padding: 1rem;
    /* border: 1px solid black; */
}
#account .canvas-widget > div {
    /* border: 1px solid #cccccc; */
    border-radius: 10px;
    /* padding: 2rem; */
    /* background-color: #333; */
    height: 100%;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding-bottom: 2rem;
}

#account .canvas-widget .widget-name {
    text-align: center;
    font-weight: 400;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

#account .canvas-widget:hover {
    cursor: pointer;
    transform: scale(1.02);
}

#account .canvas-widget p {
    text-align: center;
    margin-bottom: 0px;
    /* color: #ccc; */
    font-size: 0.9rem;
}

#account .canvas-widget img {
    /* margin-left: 50%;
    transform: translate(-50%, 0); */
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

#account .account-details {
    padding: 2rem;
    /* background-color: white; */
}

#profilePic .editWrapper
{
    background-color: #333;
    position: absolute;
    right: 0.5rem;
    bottom: 0.5rem;
    border-radius: 5rem;
    border: 5px solid white;
    transition: ease-out 0.2s;

}

#profilePic .editWrapper label
{
    margin-bottom: 0rem;
    padding: 1rem;
    cursor: pointer;

}

#profilePic .editWrapper img
{
    height: 1.5rem;
    width: unset;
}

#profilePic .editWrapper:hover
{
    background-color:black;
}

#account .profileDetails
{
    text-align:  center;
}

#account .profileDetails h1
{
    text-align :center; 
    font-weight:500;
    font-size:3rem;
    margin-bottom:0.5rem;
}

.file-input
{
    display: none;
}


@media (min-width:992px){
    #account .profileDetails
    {
        text-align:  left;
    }
    
    #account .profileDetails h1
    {
        text-align :left; 
    }

    
    #canvas-manager h2
    {
        text-align: left;
    }
}
#learn-more{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#learn-more h3{
  text-align: center;
  font-weight: 400;
  font-family: sofia-pro;
  margin-top: 4rem;
  margin-bottom: 2rem;
  font-size: 1.75rem;
}

#learn-more .btn{
  height:-webkit-max-content;
  height:-moz-max-content;
  height:max-content;
  text-transform: capitalize;
  margin:0;
  /* width: 120px; */
  min-width: 120px;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  background-color: #999999;
}

#learn-more .btn:hover, #learn-more .btn:active{
  background-color: #000000;
}

#learn-more h5{
  margin-bottom: 0.5rem;
  font-weight: bold;
  text-align: center;
  font-size: 1.25rem;
}

#learn-more p{
  color:#999999;
  text-align: center;
}

#learn-more h1{
  font-weight: 400;
  margin-bottom: 2rem;
}
#notifications{
  width:60%;
}

#notifications .read-notification{
  background-color: #F5F5F5;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  /* background-color: #EBEBEB; */
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
  border-radius: 5px;
  cursor: pointer;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

#notifications .unread-notification{
  background-color: #FFFFFF;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  /* background-color: #EBEBEB; */
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
  border-radius: 5px;
  cursor: pointer;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

#notifications .unread-notification h6, #notifications .read-notification h6{
  margin-bottom: 0.2rem;
}

#notifications .unread-notification p, #notifications .read-notification p{
  font-size: 12px;
  color: #666666;
}

@media (min-width: 300px){
  #notifications{
    width:100%;
  }
}

@media (min-width: 600px){
  #notifications{
    width:60%;
  }
}

@media (min-width: 1000px){

}
.estimate-input-field {
    border: 1px solid grey;
    padding: 10px;
    font-size: 18px;
    margin: 5px 0px;
    width: 100%;
}

.add-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background: green;
    margin: auto;
    color: white;
}

.breakdown-table {
    width: 100%;
    margin: 10px 0px;
    background: #eee;
}

.breakdown-table th,
.breakdown-table td {
    padding: 10px;
}

.breakdown-table th {
    border-bottom: 1px solid black;
}

.breakdown-table tr:hover {
    background: #ddd;
}

.breakdown-table button {
    padding: 0;
    margin: auto;
    background: none;
    margin: none;
    border: none;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.breakdown-table button img {
    width: 100%;
    height: 100%;
}

#manual-estimation{
  display: flex;
  min-height: 100vh;
  font-family: 'rand regular';
}

.header-space{
  padding-bottom: 1rem;
  height: auto;
  /* faking the grey|white background divide*/
  background: linear-gradient(90deg, #f4f4f4 50%, white 50% );
  display: flex;
  font-family: 'rand regular';
}

.header-space h3{
  padding-top: 1.5rem;
  padding-left:3rem;
}

/* DETAILS */
#manual-estimation .details{
  flex: 1 1;
  background-color: #f4f4f4;
}

#manual-estimation .details .details-container{
  padding: 0 3rem 0 3rem;
}

/* ACTIONS */
#manual-estimation .actions{
  flex: 1 1;
}

#manual-estimation .actions .actions-container{
  padding: 0 3rem 0 3rem;
}

/* ACTION BUTTONS */

#action-buttons{
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #0047FF;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

#action-buttons .question{
  cursor: pointer;
}

#action-buttons .question h6{
  font-size: 0.9rem;
}

#action-buttons .question h6:hover, #action-buttons .question h6:active, #action-buttons .question h6:focus{
  text-decoration: underline;
}

#action-buttons button{
  padding: 0.7rem 1rem 0.7rem 1rem;
  font-size: 0.8rem;
  text-transform: capitalize;
  background-color: #0047FF;
  color: #fff;
  border: none;
  border-radius: 5px;
}

/* PROJECT DETAILS */
#project-details{
  padding-bottom: 2rem;
}

#project-details .images-container img{
 margin: 0.5rem;
}

#project-details h6{
  text-transform: uppercase;
  font-size: 0.75rem;
}

#project-details p{
  font-family: 'rand medium'
}

/* ESTIMATE */

.css-s66egi, .css-1puwr1k, .css-gj542y{
  background-color: #fff!important;
}

#estimate h6{
  text-transform: uppercase;
  font-size: 0.75rem;
}

#estimate label{
  text-transform: uppercase;
  font-size: 0.7rem;
  font-family: 'rand medium';
}

#estimate .form-label{
  margin-top: 2rem;
}

#estimate .add-details{
  text-align: right;
  color: #0047FF;
  cursor: pointer;
  margin-bottom: 0;
  font-size: 0.9rem;
}

#estimate .input-group-text{
  color: #000;
}

#estimate input.form-control, #estimate textarea, #estimate .input-group-text{
  border: none;
  background-color: #f2f2f2;
}

#estimate .add-details:hover, #estimate .add-details:active, #estimate .add-details:focus{
  text-decoration: underline;
}

#estimate p{
  font-family: 'rand medium';
}

#estimate .editable-labels{
  margin: 0;
  padding: 0;
  border: none;
  text-transform: uppercase;
  font-size: 0.7rem;
  font-family: 'rand medium';
  width: 100%;
  font-weight: bold;
}

#estimate .images-container .img-close-icon-container{
  margin: 0.5rem;
}

#estimate .images-container .img-close-icon-container .image{
  margin: 0;
  width: 5rem;
  height: 5rem;
  cursor: pointer;
  box-shadow : 2px 2px 6px rgba(0, 0, 0, 0.16);
}

#estimate .images-container .img-close-icon-container .cancel{
  position: absolute;
  width: 1.5rem;
  margin-left: -1rem;
  margin-top: -0.5rem;
  cursor: pointer;
}

#estimate .images-container{
  display: flex;
  flex-wrap: wrap;
}

#estimate .select-file-container{
  background-color: #0047FF;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}


/* ESTIMATE COMMENTS */
#estimate-comments{
  margin-bottom: 6rem;
}

#estimate-comments .toggle-comments{
  text-align: right;
  color: #0047FF;
  cursor: pointer;
  margin-bottom: 0;
  font-size: 0.9rem;
}

#estimate-comments .toggle-comments:hover, #estimate-comments .toggle-comments:active, #estimate-comments .toggle-comments:focus{
  text-decoration: underline;
}

#estimate-comments .input-group-btn{
  padding-top: 0;
  padding: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin: 0;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  display: flex;
  align-items: flex-end;
  /* border: 1px solid; */
}

#estimate-comments .input-group-btn:hover, #estimate-comments .input-group-btn:active, #estimate-comments .input-group-btn:focus{
  background-color: #fff;
}

#estimate-comments .comment-input{
  border: 1px solid #ced4da;
  margin-bottom: 2rem;
}

/*  */
#estimate-comments .comments-input-container{
  display: flex;
  border: 0.6px solid #a3a3a3;
  min-height: 2.5rem;
}

#estimate-comments .comments-input-container .text-image-container{
  flex: 1 1;
}

#estimate-comments .comments-input-container .text-image-container .ql-toolbar.ql-snow{
  display: none;
}

#estimate-comments .comments-input-container .text-image-container .ql-toolbar.ql-snow + .ql-container.ql-snow{
  border: none;
}

.quill > .ql-container > .ql-editor.ql-blank::before{
  font-style: normal;
  font-weight: 400;
  color: #a3a3a3;
}

#estimate-comments .comments-input-container .images-container .img-close-icon-container{
  margin: 0.5rem;
}

#estimate-comments .comments-input-container .images-container .img-close-icon-container .image{
  margin: 0;
  width: 5rem;
  height: 5rem;
  cursor: pointer;
}

#estimate-comments .comments-input-container .images-container .img-close-icon-container .cancel{
  position: absolute;
  width: 1.5rem;
  margin-left: -1rem;
  margin-top: -0.5rem;
  cursor: pointer;
}

#estimate-comments .comments-input-container .images-container{
  display: flex;
}

/* ESTIMATE COMMENT TILE */
#estimate-comment-tile{
  margin-bottom: 2rem;
}

#estimate-comment-tile .comment-header{
  display: flex;
  align-items: center;
  width: 80%;
  margin-bottom: 0.5rem;
}

#estimate-comment-tile .comment-header .profile-pic{
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 0.5rem;
}

#estimate-comment-tile .comment-header .comment-details{

}

#estimate-comment-tile .comment-header .comment-details h6{
  margin-bottom: 0.2rem;
  font-size: 0.9rem;
  font-family: 'rand medium';
}

#estimate-comment-tile .comment-header .comment-details p{
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 0.7rem;
  font-family: 'rand regular';
}

#estimate-comment-tile .self-comment, #estimate-comment-tile .other-comment{
  border-radius: 5px;
  padding: 0.7rem;
}

#estimate-comment-tile .self-comment p, #estimate-comment-tile .other-comment p{
  font-family: 'rand medium';
  font-size: 0.9rem;
}

#estimate-comment-tile .self-comment{
  background-color: #f1f1f1;
  width: 80%;
}

#estimate-comment-tile .other-comment{
  background-color: #c4c4c4;
  width: 80%;
}

#estimate-comment-tile .self-comment-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#estimate-comment-tile .other-comment-container{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.comment-media-wrapper{
  display: flex;
  flex-wrap: wrap;
}

.comment-media-wrapper .comment-media-tile{
  width: 8rem;
  height: 8rem;
  margin: 0.2rem;
  display: flex;
  align-items: center;
  border: 0.8px solid #c3c3c3;
  border-radius: 5px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.16);
}

.comment-media-wrapper .comment-media-tile .media{
  width: 7.9rem;
  max-height: 8rem;
  border-radius: inherit;
}

.comment-media-wrapper .comment-media-tile .image-actions{
  width: 1.5rem;
  position: absolute;
  align-self: flex-end;
  padding-bottom: 0.2rem;
  opacity: 0;
  cursor: pointer;
}

.comment-media-wrapper .comment-media-tile a{
  text-decoration: none;
  display: flex;
  justify-content: center;
  position: absolute;
  font-weight: 100;
  height: 8rem;
  margin-left: 0.8rem;
  margin-bottom: 0.2rem;
}

.comment-media-wrapper .comment-media-tile:hover .image-actions, .comment-media-wrapper .comment-media-tile:active .image-actions, .comment-media-wrapper .comment-media-tile:focus .image-actions{
  opacity: 1;
}

.pdf-view{
  width: 20rem;
  border: 0.8px solid #c3c3c3;
  border-radius: 5px;
  box-shadow: 2px 2px 6px rgb(0 0 0 / 16%);
  display: flex;
  align-items: center;
  margin: 0.5rem;
}

#pdf-container .pdf-maximize{
  position: absolute;
  display: flex;
  align-items: flex-end;
  opacity: 0;
}

#pdf-container:hover .pdf-maximize{
  opacity: 1;
}

.pdf-view .page-handlers{
  position: absolute;
  justify-content: space-between;
  display: flex;
  width: inherit;
  opacity: 0;
}

.pdf-view:hover .page-handlers{
  opacity: 1;
}

.pdf-view .react-pdf__Document{
 width: inherit;
 border-radius: 5px;
}

.pdf-view .react-pdf__Document .react-pdf__Page{
  width: inherit;
  border-radius: 5px;
}

.pdf-view .react-pdf__Document .react-pdf__Page .react-pdf__Page__canvas{
  height: auto!important;
  width: inherit!important;
  border-radius: 5px;
}



.pdf-modal .react-pdf__Page{
  width: 100%;
  display: flex;
  justify-content: center;
}

.pdf-modal .react-pdf__Page .react-pdf__Page__canvas{
  height: auto!important;
  width: inherit!important;
}

/* media query for max phone width */
@media (max-width: 500px){
  .header-space {
    background: #f4f4f4;
  }

  .header-space h3{
    padding-left:2rem;
  }

  #manual-estimation{
    display: flex;
    flex-direction: column;
  }

  /* ACTIONS */
  #manual-estimation .actions{
    flex: none;
  }

  /* DETAILS */
  #manual-estimation .details .details-container{
    padding: 0 2rem 0 2rem;
  }

  /* ESTIMATE */
  #estimate input.form-control, #estimate textarea, #estimate .input-group-text{
    border: none;
    background-color: #fff;
  }

  /* editable labels */
  #estimate .editable-labels{
    background-color: transparent;
  }

  /* ESTIMATE COMMENT TILE */
  #estimate-comment-tile .self-comment{
    width: 100%;
  }
  
  #estimate-comment-tile .other-comment{
    width: 100%;
  }

  #estimate-comment-tile .comment-header{
    width: 100%;
  }
}

.react-calendar__tile--now {
  background: white;
}
.react-calendar__tile--now:enabled:hover {
  background: #e6e6e6;
}

.react-calendar__month-view__days__day--weekend {
  color: #0047FF;
}
#my-estimates {
  margin-top: 3rem;
  margin-inline: auto;
  width: 100%;
  height: auto;
}

#my-estimates h3 {
  font-weight: bold;
}

#my-estimates table {
  margin-top: 2rem;
}

#my-estimates th {
  font-weight: lighter;
  color: #A3A3A3;
  border: 0px;
}

#my-estimates td {
  background-color: rgb(248, 248, 248);
  padding: 1rem;
}

#my-estimates tbody tr {
  border: 1px rgb(194, 194, 194) solid;
}

#my-estimates tr {
  text-align: center;
}

#my-estimates .edit-button {
  color: blue;
}

#my-estimates .edit-button:hover {
  cursor: pointer;
  text-decoration: underline;
}
#payment-information{

}

#payment-information .form-container{
  margin-bottom: 2rem;
}

#payment-information .form-container h3{
  
}

#payment-information .form-container h6{
  font-size: 18px;
  font-weight: 300;
  font-family: rand regular;
  margin-top: 4rem;
  margin-bottom: 3rem;
}

#payment-information .form-container p{
  font-size: 12px;
  color: #5714ac;
  /* font-weight: 300;
  font-family: rand regular; */
}

#payment-information .form-container .form-group{
  padding-left: 0;
  max-width: 25rem;
}

#payment-information .form-container .form-label{
  font-size: 12px;
  font-family: rand regular;
  font-weight: 400;
  color: #838383;
}

#payment-information .form-container .form-control{
  font-size: 12px;
  padding: 1rem 0.8rem;
}

#payment-information .form-sections-container{
 display: flex;
 width: 100%;
 max-width: 60rem;
 flex-wrap: wrap;
}

#payment-information .form-sections-container .section-1, #payment-information .form-sections-container .section-2{
  flex:1 1;
  min-width: 20rem;
}

#payment-information .status-container{
  display: flex;
  width: 100%;
  max-width: 60rem;
  flex-wrap: wrap;
}

#payment-information .status-container .account-details, #payment-information .status-container .billing-process{
  flex:1 1;
  min-width: 20rem;
}

#payment-information .submit-button{
  border: 2px solid #5714ac;
  padding: 0.5rem 3rem;
  box-shadow: 2px 2px 6px rgba(0,0,0,.16);
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 2rem;
  color: #5714ac;
  font-size: 12px;
}

#payment-information .submit-button:hover, .submit-button:active, .submit-button:focus{
  border-color: #5714ac;
  background-color: #5714ac;
  color: #FFFFFF;
  outline: none;
}

#payment-information .asterisk{
  color: #5714ac;
}

@media (min-width: 300px){
  #payment-information .form-container{
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 2rem;
  }
}

@media (min-width: 600px){
  #payment-information .form-container{
    padding-left: 8rem;
    padding-right: 8rem;
    margin-top: 4rem;
  }
}

#payment-information .code-options-container p{
 margin-bottom: 0;
 margin-right: 1rem;
 color: #838383;
}

#payment-information .code-options-container{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
 }

#payment-information .code-options{
  display: flex;
  align-items: center;
}

#payment-information .code-options .radio-btn-outer-inactive{
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 1px solid #ced4da;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
  cursor: pointer;

}

#payment-information .code-options .radio-btn-outer-inactive .radio-btn-inner-inactive{
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  background-color: #ced4da;
}

#payment-information .code-options .radio-btn-outer-active{
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 1px solid #5714ac;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
  cursor: pointer;

}

#payment-information .code-options .radio-btn-outer-active .radio-btn-inner-active{
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  background-color: #5714ac;
}
.admin-page__container {
    width: 100vw;
    height: auto;
    margin: 1.5rem;
}

.table-container {
    width: 98%;
}

.admin-page__topContainer {
    display: flex;
    width: 100%;
    margin-bottom: 2rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.admin-page__input {
    width: 30vw;
    height: 2rem;
    font-size: 1rem;
    font-weight: 400;
    padding: 10px;
    margin: 0;
    border: 1px solid grey;
}

.admin-page__emailContainer {
    display: flex;
    margin-right: 3.5rem;
    justify-content: space-evenly;
    align-items: center;
}

.admin-page__email {
    width: 20vw;
    height: 2rem;
    font-size: 1rem;
    font-weight: 400;
    border: 1px solid grey;
    padding: 10px;
    margin: 0 0.25rem 0 0;
}

.admin-page__button__addUser.btn {
    position: relative;
    margin: 0;
    /* right: 4rem; */
    padding: 0;
    height: 2rem;
    width: auto;
    padding: 0;
}

.admin-auth {
    margin: 2rem;
}

.admin-auth__container {
    display: flex;
    justify-content: start;
    height: auto;
    width: 90vw;
}

.admin-auth__container input {
    height: calc(1.5em + .75rem + 2px);
    padding: 10px;
    margin: 1rem 1rem 0 0;
}

.admin-auth__container button {
    margin: 1.25rem 0;
    height: calc(1.5em + .75rem + 2px);
    width: auto;
    padding: 0;
}

.admin-auth__warning {
    color: red;
}

.admin-page__notifications {
    float: right;
    padding: 0;
    margin-top: -2rem;
    margin-right: 3.5rem; 
    height: auto;
    max-height: 2rem;
    transition: max-height 0.2s ease-in-out;
    -webkit-transition: max-height 0.2s ease-in-out;
    -moz-transition: max-height 0.2s ease-in-out;
}

.admin-page__tr:hover {
    cursor: pointer;
}
