.gallery-wrapper
{
    min-height: 50vh;
    max-height: 50vh;
    /* border: 1px dashed grey; */
    background-color: #eee;
    overflow-y: scroll;
}

.media-drop-wrapper{
    background-color: rgb(245, 245, 245);
}

.other-documents-wrapper
{
    display: flex;
    flex-wrap: wrap;
}

.other-documents-item{
    width: 80px;
    height: fit-content;
    margin: 10px;
}

.gallery-wrapper.selected
{
    min-height: 50vh;
    max-height: 50vh;
    border: none;
    background-color: #fff;
    overflow-x: hidden;
    overflow-y: scroll;
}

button.search-upload-btn
{
    margin-top: 0px;
    border-radius: 0px;
    padding: 0.4rem;
    min-width: 100px;
    width: 100%;
    text-transform: none;
}

.file-input
{
    display: none;
}

.btn label
{
    width: 100%;
    height: 100%;
    margin: 0px;
    cursor: pointer;
}

p.search-or
{
    padding: 0.4rem;
}

.search-upload-option img
{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    max-height: 100%;
    /* max-width: 100%; */
}

div.option
{
    margin-top: 1rem;
    margin-bottom: 1rem;

}

.css-sc2rit{
    margin: auto;
}