#splash-auth {
  position: relative;
  z-index: 0;
  top: 0; left: 0;
  height: 100%;
  min-height: 100vh;
  // overflow: auto;
  width: 100vw;
  background: white;
  padding: 4rem 20%;
}

.spinner-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.spinner-container .spinner-border-lg {
  height: 4rem;
  width: 4rem;
  border-width: .3rem;
  margin-bottom: 1rem;
}

.tos-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  text-align: center;

  h2:first-of-type {
    color: var(--theme-color-1)
  }
}

.tos-container p .bold{
  font-weight: bold;
}

.tos-button {
  display: block;
  height: max-content;
  margin: 0;
  min-width: 120px;
  padding: .5rem .2rem;
  background-color: #999;
  font-weight: 700;
  border: none;
  color: #fff;
  line-height: 1.5;
  font-size: 1rem;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.cap {
  text-transform: capitalize;
}

.tos-button:hover {
  background-color: #000;
}

.tos-button:disabled {
  background-color: #999;
}

.main-btn[disabled] {
  cursor: initial;
  color: var(--theme-color-2);
  border-color: var(--theme-color-2);

  &:hover {
    cursor: initial;
    background: initial;
    color: var(--theme-color-2);
  }
}

#splash-modal_container {
  position: absolute;
  z-index: 1;
  top: 0; left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.699);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal Container */
.splash-modal_body {
  height: 100%;
  width: 100%;
  max-height: 900px;
  max-width: 768px;
  background: white;
  padding: 2rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

/* Modal Title */
.splash-modal_body div[name="title"] {

  .logo_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: max-content;

    h2 {
      text-transform: uppercase;
      color: var(--theme-color-1);
      margin-bottom: 1rem;
    }
  }
}

/* Modal Body */
.splash-modal_body div[name="body"] {
  min-height: 350px;
  max-height: 500px;
  max-width: max-content;
  flex-grow: 1;
  overflow-y: auto;
}

/* Modal Footer */
.splash-modal_body div[name='footer'] {
    display: flex;
    flex-direction: column;

    .checkbox_container {
      margin: 1rem auto;

      input, label {
        margin: 0;
      }
      
    }

    .main-btn_container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      span {
        font-size: 12px;
        color: darkgrey;
        cursor: pointer;

        &:hover {
          color: black;
          text-decoration: underline;
        }
      }
    }
  }