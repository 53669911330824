@font-face {
    font-family: 'rand black italic';
    src: url("./fonts/Rand-Black-Italic.otf");
}
@font-face {
    font-family: 'rand black';
    src: url("./fonts/Rand-Black.otf");
}
@font-face {
    font-family: 'rand bold italic';
    src: url("./fonts/Rand-Bold-Italic.otf");
}
@font-face {
    font-family: 'rand bold';
    src: url("./fonts/Rand-Bold.otf");
}
@font-face {
    font-family: 'rand heavy italic';
    src: url("./fonts/Rand-Heavy-Italic.otf");
}
@font-face {
    font-family: 'rand heavy';
    src: url("./fonts/Rand-Heavy.otf");
}

@font-face {
    font-family: 'rand italic';
    src: url("./fonts/Rand-Italic.otf");
}
@font-face {
    font-family: 'rand light italic';
    src: url("./fonts/Rand-Light-Italic.otf");
}
@font-face {
    font-family: 'rand light';
    src: url("./fonts/Rand-Light.otf");
}
@font-face {
    font-family: 'rand medium italic';
    src: url("./fonts/Rand-Medium-Italic.otf");
}
@font-face {
    font-family: 'rand medium';
    src: url("./fonts/Rand-Medium.otf");
}
@font-face {
    font-family: 'rand regular';
    src: url("./fonts/Rand-Regular.otf");
}
@font-face {
    font-family: 'rand thin italic';
    src: url("./fonts/Rand-Thin-Italic.otf");
}
@font-face {
    font-family: 'rand thin';
    src: url("./fonts/Rand-Thin.otf");
}

p, div, button, span
{
    font-family: 'rand regular';
}

h1, h2, h3
{
    font-family: 'rand heavy';
}

h4, h5, h6
{
    font-family: 'rand bold';
}

.navbar-brand
{
    font-family: 'rand bold';
  
}

.makeStyles-root-1 * {
  font-family: 'rand regular' !important;
}

.main-btn{
    /* margin-top: 1rem; */
    margin-bottom: 1rem;
    border: 2px solid #5714ac;
    padding: 0.5rem 3rem;
    font-size: 0.7rem;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
    /* margin-left: 1rem; */
    cursor: pointer;
    background-color: #ffffff;
    border-radius: 2rem;
    font-size: 1rem;
    color: #5714ac;
  }
  
  .main-btn:hover, .main-btn:active, .main-btn:focus{
    border-color: #5714ac;
    background-color: #5714ac;
    color: #FFFFFF;
    outline: none;
  }
