#notifications{
  width:60%;
}

#notifications .read-notification{
  background-color: #F5F5F5;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  /* background-color: #EBEBEB; */
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
  border-radius: 5px;
  cursor: pointer;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

#notifications .unread-notification{
  background-color: #FFFFFF;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  /* background-color: #EBEBEB; */
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
  border-radius: 5px;
  cursor: pointer;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

#notifications .unread-notification h6, #notifications .read-notification h6{
  margin-bottom: 0.2rem;
}

#notifications .unread-notification p, #notifications .read-notification p{
  font-size: 12px;
  color: #666666;
}

@media (min-width: 300px){
  #notifications{
    width:100%;
  }
}

@media (min-width: 600px){
  #notifications{
    width:60%;
  }
}

@media (min-width: 1000px){

}