body {
    /* background-color: #222; */
}

.canvas-tile-wrapper {
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

#canvas {
    padding: 1rem;
    font-family: sofia-pro-soft, sans-serif;
    font-weight: 700;
    font-style: normal;
}

#canvas h5 {
    font-family: bigmoore, serif;
    margin-top: 1rem;
    /* color: white; */
}

.canvas-tile-wrapper.image-tile {
    border-radius: 0.5rem;
}

.canvas-tile-wrapper .sketch {
    padding: 0px;
    /* max-height: 300px; */
}

.canvas-tile-wrapper.image-tile > div {
    padding: 0px;
}

.canvas-tile-wrapper.image-tile.upload img {
    /* max-height: 300px; */
}

.canvas-tile-wrapper.image-tile .canvas-tile {
    margin-bottom: 0px;
}

.canvas-tile-wrapper.image-tile img {
    height: 100%;
    width: auto;
    position: absolute;
    top: -9999px;
    left: -9999px;
    right: -9999px;
    bottom: -9999px;
    margin: auto;
}

.canvas-tile {
    min-height: 300px;

    border-radius: 0.5rem;
    /* margin-bottom: 1rem; */
    /* border: 1px solid #777; */
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    height: 90%;
    padding-top: 2rem;
    /* background-color: #111; */
    padding: 0rem;
}

.canvas-tile p {
    font-weight: 200;
    /* color: white; */
}

.canvas-tile:hover {
    background-color: #eee;
    transition-duration: 0.2s;
}

.canvas-tile h5 {
    text-align: center;
    font-size: 1.1rem;
}

.canvas-tile p.option-text {
    text-align: center;
    font-size: 0.85rem;
}

.canvas-tile > div {
    height: 100%;
    position: relative;
    /* transform: translate(0, -50%); */
}

.canvas-tile {
    overflow: hidden;
}

.canvas-tile > div > div {
    /* margin-top: 50%;
    transform: translate(0, -50%); */
}

#canvas .comment-tile {
    min-height: 300px;
    /* border: 1px solid #777; */
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    border-radius: 0.5rem;
    height: min-content;
    overflow: hidden;
    font-weight: 400;
    /* background-color: #eee; */
    transition: all 0.2s ease-in-out;
    margin-top: 0rem;
    margin-bottom: 0rem;
}

#canvas .comment-tile:hover {
    /* transform: scale(1.01); */
}

#canvas .reply-to {
    /* background-color: #111; */
    /* padding: 0px; */
    opacity: 0.8;
}

#canvas .reply-to .canvas-tile {
    padding: 0px;
}

#canvas .reply-to .canvas-tile img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
    height: auto;
}

#canvas .comment-tile .btn {
    width: 100%;
    background-color: white;
    color: black;
    border: 1px solid black;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

#canvas .comment-tile p {
    font-weight: 200;
    font-size: 1.1rem;
    /* margin-top: 1rem; */
    padding: 1rem;
}

#canvas .comment-tile-render {
    overflow: hidden;
    /* background-color: #111; */
}

#canvas .user-profile {
    background-color: transparent;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
}

#canvas .user-profile img {
    height: 2rem;
    border-radius: 2rem;
    width: auto;
    /* margin-right: 1rem; */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: auto;
    margin-bottom: auto;
}

#canvas .user-profile .name {
    font-weight: 200;
    margin-bottom: 0px;
    margin-left: 2rem;
}

#canvas .user-profile .time {
    font-weight: 200;
    font-size: 0.85rem;
    color: #888888;
    margin-bottom: 0rem;
    margin-left: 2rem;
    margin-top: -0.2rem;
}

#canvas .comment-tile img,
#canvas .canvas-tile .img {
    height: 100%;
    width: auto;
    position: absolute;
    top: -9999px;
    left: -9999px;
    right: -9999px;
    bottom: -9999px;
    margin: auto;
}

#canvas .add-content-tile {
    min-height: 300px;
    /* background-color: rgba(255,255,255,0.3); */
    /* border: 1px solid #777; */
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    border-radius: 0.5rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

#canvas .add-content-tile:hover {
    /* min-height: 300px; */
    /* background-color: rgba(255,255,255,0.8); */
    /* border: 1px solid #888888; */
}

@media (min-width: 992px) {
    #canvas {
        padding: 4rem;
    }

    #canvas .tile-controls {
        opacity: 0;
    }

    #canvas .canvas-tile-wrapper:hover .tile-controls {
        opacity: 1;
    }

    #canvas .canvas-tile-wrapper:active .tile-controls {
        opacity: 1;
    }

    #canvas .comment-tile .img-wrapper .maximizeButton {
        opacity: 0;
    }

    #canvas .comment-tile .img-wrapper:hover .maximizeButton {
        opacity: 1;
    }
}

#canvas .comment-tile .img-wrapper {
    overflow: hidden;
}

#canvas .tile-controls {
    color: #666;
    font-weight: 100;
}

#canvas .tile-controls a {
    text-align: right;
    cursor: pointer;
}

#canvas .tile-controls a:hover {
    color: #eee;
}

#canvas .tile-controls a:active {
    color: #eee;
}

#canvas .reply-to {
    min-height: 300px;
    overflow: hidden;
}

#canvas .reply-to h6 {
    font-family: sofia-pro, sans-serif;
    /* font-weight: 700; */
    color: white;
    padding: 1rem;
    font-size: 1.2rem;
}

#canvas .reply-to .canvas-tile {
    height: 70%;
}

#canvas .next-steps p {
    font-weight: 200;
    font-size: 0.85rem;
}

#canvas .next-steps .btn {
    font-weight: 200;
    font-size: 0.85rem;
    padding: 0.5rem;
}

#canvas .canvas-sketch {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: transparent;
}

#canvas .quill p {
    font-weight: normal;
    font-size: 1rem;
    padding: 0rem;
}

.ql-editor p {
    color: black;
}

.tag-select{
    border: 1px solid black;
    color: black;
    font-weight: 700;
    text-transform: uppercase;
}

.admin-comment-wrapper{
    background-color: rgba(255,255,255,0.5);
}

.normal-comment-wrapper{
    background-color: rgba(255,255,255,0.5);
}