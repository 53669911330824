.tag {
    cursor: pointer;
    font-size: 20px;
    padding: 4px 8px;
    font-weight: bold;
    margin: 10px;
}

.inactive {
    /* color: white; */
    background-color: black;
}

.active {
    /* color: black; */
    /* background-color: white; */
}

h1 {
    /* color: white; */
}

h3 {
    /* color: white; */
}

input {
    margin: 30px 0px;
}

label {
    /* color: white; */
}
