.custom-switch .custom-control-input:checked~.custom-control-label::after{
  background-color:#000000;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #000000;
  border-color: #a3a3a3;
  background-color: #fff;
}

#profile .user-details-container, #profile .description-container{
  padding-top: 1rem;
  /* padding-left: 6rem;
  padding-right: 6rem; */
  justify-content: flex-start;
}

#profile .user-details-container{
  align-items:center;
}

#profile .description-container{
  align-items: baseline;
}


#profile .user-details-container h3{
  /* font-family: sofia-pro; */
  margin-bottom: 5px;
  word-break: break-word;
}

#profile .user-details-container h4{
  font-weight: 400;
  /* font-size: 22px; */
  color: #666666;
  /* font-family: sofia-pro; */
  margin-bottom: 10px;
  word-break: break-word;
}

#profile .user-details-container h6{
  font-size: 12px;
  /* font-family: sofia-pro; */
  margin-bottom: 20px;
  word-break: break-word;
}

.react-switch-bg{
  border: 1px solid #cccccc;
}

#profile ::placeholder{
  color:#a3a3a3;
  font-weight: 100;
  font-size: 12px;
  /* font-family: sofia-pro; */
}

#profile :-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #a3a3a3;
  font-weight: 100;
  font-size: 12px;
  /* font-family: sofia-pro; */
}

#profile ::-ms-input-placeholder { /* Microsoft Edge */
  color: #a3a3a3;
  font-weight: 100;
  font-size: 12px;
  /* font-family: sofia-pro; */
}

/* for syncloader */
#profile .css-1hve7j5{
  margin:auto;
}

/* Edit name */
#profile .user-details-container .name .edit-name{
  display:none;
}

#profile .user-details-container .name:hover .edit-name{
  display:flex;
}

#profile .user-details-container .name:active .edit-name{
  display:flex;
}

/* edit description */
#profile .description-container .description .edit-description{
  display:none;
}

#profile .description-container .description:hover .edit-description{
  display:flex;
}

#profile .description-container .description:active .edit-description{
  display:flex;
}

/* Edit company */
#profile .user-details-container .company .edit-company{
  display:none;
}

#profile .user-details-container .company:hover .edit-company{
  display:flex;
}

#profile .user-details-container .company:active .edit-company{
  display:flex;
}

/* Edit location */
#profile .user-details-container .location .edit-location{
  display:none;
}

#profile .user-details-container .location:hover .edit-location{
  display:flex;
  padding-left: 15px;
}

#profile .user-details-container .location:active .edit-location{
  display:flex;
  padding-left: 15px;
}

/* small devices like iphone 4 or iphone 5 */
@media (min-width: 300px){
  #profile .user-details-container{
    padding-left: 1rem;
    padding-right: 1rem;
    justify-content:center;
  }
   #profile .description-container{
    padding-left: 1rem;
    padding-right: 1rem;
    justify-content:flex-start;
  }
  #profile .switch-container{
    width:350px;
    justify-content: space-evenly;
  }
  #profile .switch-container .switch-button{
    margin:0;
    color:#FFFFFF;
    padding:0.5rem; 
    font-size:12px;
    text-transform:capitalize; 
    border:none;
    width:80px;
    border-radius:5px; 
    margin-left:10px;
  }
  /* name, company, location container */
  #profile .name-container{
    padding-left: 1rem;
  }
  #profile .name-wrapper{
    /* justify-content: center; */
  }
  #profile .user-details-container h3{
    font-size: 16px;
    margin-bottom:0.2rem;
    margin-top: 0;
  }
  #profile .user-details-container h4{
    font-size: 14px;
    margin-bottom:0.2rem;
  }
  #profile .description-container .description{
    padding-left: 0;
  }
  #profile .switch-container{
    margin-top: 1.5rem;
  }
}

/* Progress Bar */
#progress-bar {
  position: relative;
  width: 100%;
  font-family: 'rand regular';
  font-weight: 400;
  color: rgb(102, 102, 102);
  margin-bottom: 1rem;
}

.progress-stepper-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 100%;
}

.progress-stepper-container span {
  flex: 1 1 auto;
  min-height: 1rem;
  margin-right: .25rem;
}

.missing-progress-container {
  font-size: 14px;
  width: max-content;
  padding: 1.5rem 1.5rem;
  border: 1px lightgrey solid;
  position: absolute;
  z-index: 2;
  margin-top: .5rem;
  background: white;
  border-radius: 2rem;
}

.missing-progress-container .details {
  margin-left: 1rem;
  margin-bottom: 0;
}

.missing-progress-container .question-ul {
  list-style-type: '- ';
}

.form-control[readonly] {
    background-color: rgba(233, 232, 232, 0.425);
    opacity: 1;
}

/* usual mobile devices with width more than 340 */
@media (min-width: 340px){
  #profile .user-details-container h3{
    font-size: 20px;
    margin-bottom:0.2rem;
    margin-top: 0;
  }
  #profile .user-details-container h4{
    font-size: 16px;
    margin-bottom:0.2rem;
  }
}

@media (min-width: 600px){
  #profile .user-details-container, #profile .description-container{
    padding-left: 1rem;
    padding-right: 1rem;
    justify-content: flex-start;
  }
  #profile .user-details-container h3{
    font-size: 28px;
    margin-bottom:0.5rem;
    margin-top: 1rem;
  }
  #profile .user-details-container h4{
    font-size: 22px;
    margin-bottom:0.5rem;
  }
  #profile .user-details-container{
    justify-content: center;
  }
  #profile .switch-container{
    width:200px;
    justify-content: flex-end;
    margin-top: 0;
  }
  #profile .switch-container .switch-button{
     width:100px; 
  }
  /* name, company, location container */
  #profile .name-container{
    padding-left: 2rem;
  }
  #profile .name-wrapper{
    justify-content: flex-start;
  }
  #profile .description-container .description{
    padding-left: 2rem;
  }
}

@media (min-width: 1000px){
  #profile .user-details-container, #profile .description-container{
    /* padding-left: 6rem;
    padding-right:6rem; */
  }
  #profile .switch-container{
    width:200px;
  }
}

.user-email-link {
  cursor: pointer;
  color: grey;
}

.user-email-link:hover {
  color: black;
  text-decoration: underline;
}

/* Location searcg */
.location-search-input{
  margin: 0;
  padding: 0.5rem 0.5rem;
  font-size: 1rem;
  width: 100%;
  border: 1px solid #ced4da;
}

.suggestion-item{
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: normal;
  cursor: pointer;
}

.suggestion-item:hover{
  background-color: #f1f1f1;
}

.autocomplete-dropdown-container{
  border: 1px solid #ced4da;
}

.ReactCrop img{
  width: 100%;
}

.crop-btn{
  background-color: #000;
  color: #fff;
  padding: 1rem 3rem;
  border: 1px solid #000;
  border-radius: 5px;
}