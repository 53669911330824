#my-estimates {
  margin-top: 3rem;
  margin-inline: auto;
  width: 100%;
  height: auto;
}

#my-estimates h3 {
  font-weight: bold;
}

#my-estimates table {
  margin-top: 2rem;
}

#my-estimates th {
  font-weight: lighter;
  color: #A3A3A3;
  border: 0px;
}

#my-estimates td {
  background-color: rgb(248, 248, 248);
  padding: 1rem;
}

#my-estimates tbody tr {
  border: 1px rgb(194, 194, 194) solid;
}

#my-estimates tr {
  text-align: center;
}

#my-estimates .edit-button {
  color: blue;
}

#my-estimates .edit-button:hover {
  cursor: pointer;
  text-decoration: underline;
}