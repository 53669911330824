section {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-color: #222;
    color: white;
    font-size: 30px;
}

img {
    width: 100px;
}
