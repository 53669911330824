.estimate-input-field {
    border: 1px solid grey;
    padding: 10px;
    font-size: 18px;
    margin: 5px 0px;
    width: 100%;
}

.add-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background: green;
    margin: auto;
    color: white;
}

.breakdown-table {
    width: 100%;
    margin: 10px 0px;
    background: #eee;
}

.breakdown-table th,
.breakdown-table td {
    padding: 10px;
}

.breakdown-table th {
    border-bottom: 1px solid black;
}

.breakdown-table tr:hover {
    background: #ddd;
}

.breakdown-table button {
    padding: 0;
    margin: auto;
    background: none;
    margin: none;
    border: none;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.breakdown-table button img {
    width: 100%;
    height: 100%;
}
