#payment-information{

}

#payment-information .form-container{
  margin-bottom: 2rem;
}

#payment-information .form-container h3{
  
}

#payment-information .form-container h6{
  font-size: 18px;
  font-weight: 300;
  font-family: rand regular;
  margin-top: 4rem;
  margin-bottom: 3rem;
}

#payment-information .form-container p{
  font-size: 12px;
  color: #5714ac;
  /* font-weight: 300;
  font-family: rand regular; */
}

#payment-information .form-container .form-group{
  padding-left: 0;
  max-width: 25rem;
}

#payment-information .form-container .form-label{
  font-size: 12px;
  font-family: rand regular;
  font-weight: 400;
  color: #838383;
}

#payment-information .form-container .form-control{
  font-size: 12px;
  padding: 1rem 0.8rem;
}

#payment-information .form-sections-container{
 display: flex;
 width: 100%;
 max-width: 60rem;
 flex-wrap: wrap;
}

#payment-information .form-sections-container .section-1, #payment-information .form-sections-container .section-2{
  flex:1;
  min-width: 20rem;
}

#payment-information .status-container{
  display: flex;
  width: 100%;
  max-width: 60rem;
  flex-wrap: wrap;
}

#payment-information .status-container .account-details, #payment-information .status-container .billing-process{
  flex:1;
  min-width: 20rem;
}

#payment-information .submit-button{
  border: 2px solid #5714ac;
  padding: 0.5rem 3rem;
  box-shadow: 2px 2px 6px rgba(0,0,0,.16);
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 2rem;
  color: #5714ac;
  font-size: 12px;
}

#payment-information .submit-button:hover, .submit-button:active, .submit-button:focus{
  border-color: #5714ac;
  background-color: #5714ac;
  color: #FFFFFF;
  outline: none;
}

#payment-information .asterisk{
  color: #5714ac;
}

@media (min-width: 300px){
  #payment-information .form-container{
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 2rem;
  }
}

@media (min-width: 600px){
  #payment-information .form-container{
    padding-left: 8rem;
    padding-right: 8rem;
    margin-top: 4rem;
  }
}

#payment-information .code-options-container p{
 margin-bottom: 0;
 margin-right: 1rem;
 color: #838383;
}

#payment-information .code-options-container{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
 }

#payment-information .code-options{
  display: flex;
  align-items: center;
}

#payment-information .code-options .radio-btn-outer-inactive{
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 1px solid #ced4da;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
  cursor: pointer;

}

#payment-information .code-options .radio-btn-outer-inactive .radio-btn-inner-inactive{
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  background-color: #ced4da;
}

#payment-information .code-options .radio-btn-outer-active{
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 1px solid #5714ac;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
  cursor: pointer;

}

#payment-information .code-options .radio-btn-outer-active .radio-btn-inner-active{
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  background-color: #5714ac;
}