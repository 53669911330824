body {
    font-family: sofia-pro-soft, sans-serif;
    font-weight: 700;
    font-style: normal;
}

#naya-navbar {
    margin-bottom: 2rem;
}

#naya-navbar .navbar-brand {
    /* font-family: bigmoore, serif; */
    font-size: 2rem;
    color: white;
}

h2 {
    font-family: orpheuspro, serif;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    font-size: 2.4rem;
    margin-bottom: 5rem;
}


#login-btn
{
    margin-top:2rem;
    margin-bottom: 2rem;
    text-transform:none;
    font-weight:bold;
    font-size:1.2rem;
    padding-left: 0;
    padding-right: 0;
    min-width: 10rem;
    display: flex;
    flex-basis: 0;
    justify-content: center;
    align-items: center;
    
}

#google-btn{
    background-color: white;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    font-family: 'Roboto', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 3.5rem;
    margin: auto;
    margin-top: 2rem !important; 
    padding: 0 2rem;
    font-size: 1.2rem;
    font-weight: 700;
    border: 1px solid #fafafa;
    border-radius: .25rem;
}

#facebook-btn{
    background-color: #3c5898;
    color: white;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    font-family: 'Roboto', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 3.5rem;
    margin: auto;
    margin-top: 2rem !important;
    padding: 0 2rem;
    font-size: 1.2rem;
    font-weight: 700;
    border: none !important;
    border-radius: .25rem !important;
    
}

.question-h2 {
    margin-bottom: 0rem;
    /* color: white; */
}

.question-h4 {
    margin-bottom: 4rem;
    /* color: white; */
}

h2 img {
    width: 1.5rem;
    height: 1.5rem;
}

button.btn {
    /* color: white; */
    /* background-color: black; */
    /* padding: 1rem; */
    /* font-size: 0.8rem; */
    padding-top: 0.8rem;

    padding-bottom: 0.8rem;
    padding-left: 5rem;
    padding-right: 5rem;
    border: none;
    font-weight: 700;
    text-transform: uppercase;
    /* border-radius: 50px; */
    min-width: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
}

.navbar-continue .btn {
    margin: 0;
    margin-top: 50%;
    transform: translate(0, -50%);
    /* position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 3rem; */
}

p.skip-button {
    width: 100%;
    /* margin-left: auto;
    margin-right: auto; */
    text-align: center;
    font-size: 0.9rem;
}

div.vertical-center {
    position: absolute;
    margin-top: 50vh;
    transform: translate(0, -25vh);
}

p.landing-option {
    text-align: center;
}

img.landing-option-img {
    border-radius: 100%;
}

.question-other-input {
    color: black;
    font-size: 0.85rem;
    font-weight: 700;
    text-align: center;
    padding: 0rem;
    border: 1px solid black;
    border-radius: 0;
}

.navbar-continue {
    display: none;
}



#register-wrapper {
    padding: 0rem;
    margin-top: 3rem;
}

#register-wrapper .btn {
    /* color: white; */
    border: 1px solid white;
    margin-top: 0rem;
    border-radius: 0;
    font-weight: 400;
}

input.form-control {
    /* background-color: black;
    color: white; */
    /* border: 1px solid white; */
    border-radius: 0px;
    padding: 1.3rem;
    margin-bottom: 1.5rem;
    margin-top: 0rem;
}

.auth-wrapper
{
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-left: 0px;
    padding-right: 0px;
}

h1 {
    /* font-family: bigmoore, serif; */
    font-weight: 700;
    margin-bottom: 1.5rem;
    font-size: 3.5rem;
}

h4 {
    font-weight: 100;
    font-size: 1rem;
}

h5 {
    /* font-weight: 100; */
    font-size: 1rem;
    margin-bottom: 1rem;
}

.wrapper {
    /* transform: translate(0, -40vh) */
}

@media (min-width: 768px) {
    .wrapper {
        /* transform: translate(0, -25vh) */
    }
}

.img-wrapper {
    padding: 0;
}

div.alert {
    font-weight: 400;
    font-size: 0.8rem;
}

h5 a {
    color: white;
    font-weight: 400;
}

.form-group select {
    background-color: black;
    color: white;
    border: 1px solid white;
    border-radius: 0px;
}

input {
    font-size: 22px;
    padding: 20px 40px;
    /* color: white; */
    /* background-color: black; */
    border: 2px solid black;
}


@media (min-width: 992px) {
    .navbar-continue {
        display: inline-block;
    }

    #register-wrapper {
        /* color: white; */
        padding: 3rem 0rem 3rem 0rem;
        /* position: absolute; */
        /* top: 50vh; */
        font-size: 1.1rem;
    
        /* position: fixed; */
        /* top: 50%; */
        /* left: 50%; */
        /* bring your own prefixes */
        /* transform: translate(-50%, -50%); */
        /* bottom: 0;
        left: 0;
        right: 0; */
    
        margin: auto;
        /* transform: translate(0, -25vh); */
    }

    
}

.info{
    text-align: center;
}

.box{
    min-width: 200px;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
}

.box.checked{
    background-color: black !important;
    border: 1px solid black !important;
    color: white;
}

.box:hover{
    background-color: darkgrey;
    color: white;
    border: 1px solid darkgrey;
}

.form-group{
    padding: 0 2rem;
}

@media only screen and (max-width: 425px) {
    .vertical {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
  }