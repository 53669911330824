p {
    /* color: white; */
    font-weight: normal;
}

.image {
    background-color: white;
    margin: 20px;
    width: 128px;
    border: 2px solid white;
}
