#manual-estimation{
  display: flex;
  min-height: 100vh;
  font-family: 'rand regular';
}

.header-space{
  padding-bottom: 1rem;
  height: auto;
  /* faking the grey|white background divide*/
  background: linear-gradient(90deg, #f4f4f4 50%, white 50% );
  display: flex;
  font-family: 'rand regular';
}

.header-space h3{
  padding-top: 1.5rem;
  padding-left:3rem;
}

/* DETAILS */
#manual-estimation .details{
  flex: 1;
  background-color: #f4f4f4;
}

#manual-estimation .details .details-container{
  padding: 0 3rem 0 3rem;
}

/* ACTIONS */
#manual-estimation .actions{
  flex: 1;
}

#manual-estimation .actions .actions-container{
  padding: 0 3rem 0 3rem;
}

/* ACTION BUTTONS */

#action-buttons{
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #0047FF;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

#action-buttons .question{
  cursor: pointer;
}

#action-buttons .question h6{
  font-size: 0.9rem;
}

#action-buttons .question h6:hover, #action-buttons .question h6:active, #action-buttons .question h6:focus{
  text-decoration: underline;
}

#action-buttons button{
  padding: 0.7rem 1rem 0.7rem 1rem;
  font-size: 0.8rem;
  text-transform: capitalize;
  background-color: #0047FF;
  color: #fff;
  border: none;
  border-radius: 5px;
}

/* PROJECT DETAILS */
#project-details{
  padding-bottom: 2rem;
}

#project-details .images-container img{
 margin: 0.5rem;
}

#project-details h6{
  text-transform: uppercase;
  font-size: 0.75rem;
}

#project-details p{
  font-family: 'rand medium'
}

/* ESTIMATE */

.css-s66egi, .css-1puwr1k, .css-gj542y{
  background-color: #fff!important;
}

#estimate h6{
  text-transform: uppercase;
  font-size: 0.75rem;
}

#estimate label{
  text-transform: uppercase;
  font-size: 0.7rem;
  font-family: 'rand medium';
}

#estimate .form-label{
  margin-top: 2rem;
}

#estimate .add-details{
  text-align: right;
  color: #0047FF;
  cursor: pointer;
  margin-bottom: 0;
  font-size: 0.9rem;
}

#estimate .input-group-text{
  color: #000;
}

#estimate input.form-control, #estimate textarea, #estimate .input-group-text{
  border: none;
  background-color: #f2f2f2;
}

#estimate .add-details:hover, #estimate .add-details:active, #estimate .add-details:focus{
  text-decoration: underline;
}

#estimate p{
  font-family: 'rand medium';
}

#estimate .editable-labels{
  margin: 0;
  padding: 0;
  border: none;
  text-transform: uppercase;
  font-size: 0.7rem;
  font-family: 'rand medium';
  width: 100%;
  font-weight: bold;
}

#estimate .images-container .img-close-icon-container{
  margin: 0.5rem;
}

#estimate .images-container .img-close-icon-container .image{
  margin: 0;
  width: 5rem;
  height: 5rem;
  cursor: pointer;
  box-shadow : 2px 2px 6px rgba(0, 0, 0, 0.16);
}

#estimate .images-container .img-close-icon-container .cancel{
  position: absolute;
  width: 1.5rem;
  margin-left: -1rem;
  margin-top: -0.5rem;
  cursor: pointer;
}

#estimate .images-container{
  display: flex;
  flex-wrap: wrap;
}

#estimate .select-file-container{
  background-color: #0047FF;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}


/* ESTIMATE COMMENTS */
#estimate-comments{
  margin-bottom: 6rem;
}

#estimate-comments .toggle-comments{
  text-align: right;
  color: #0047FF;
  cursor: pointer;
  margin-bottom: 0;
  font-size: 0.9rem;
}

#estimate-comments .toggle-comments:hover, #estimate-comments .toggle-comments:active, #estimate-comments .toggle-comments:focus{
  text-decoration: underline;
}

#estimate-comments .input-group-btn{
  padding-top: 0;
  padding: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin: 0;
  min-width: fit-content;
  display: flex;
  align-items: flex-end;
  /* border: 1px solid; */
}

#estimate-comments .input-group-btn:hover, #estimate-comments .input-group-btn:active, #estimate-comments .input-group-btn:focus{
  background-color: #fff;
}

#estimate-comments .comment-input{
  border: 1px solid #ced4da;
  margin-bottom: 2rem;
}

/*  */
#estimate-comments .comments-input-container{
  display: flex;
  border: 0.6px solid #a3a3a3;
  min-height: 2.5rem;
}

#estimate-comments .comments-input-container .text-image-container{
  flex: 1;
}

#estimate-comments .comments-input-container .text-image-container .ql-toolbar.ql-snow{
  display: none;
}

#estimate-comments .comments-input-container .text-image-container .ql-toolbar.ql-snow + .ql-container.ql-snow{
  border: none;
}

.quill > .ql-container > .ql-editor.ql-blank::before{
  font-style: normal;
  font-weight: 400;
  color: #a3a3a3;
}

#estimate-comments .comments-input-container .images-container .img-close-icon-container{
  margin: 0.5rem;
}

#estimate-comments .comments-input-container .images-container .img-close-icon-container .image{
  margin: 0;
  width: 5rem;
  height: 5rem;
  cursor: pointer;
}

#estimate-comments .comments-input-container .images-container .img-close-icon-container .cancel{
  position: absolute;
  width: 1.5rem;
  margin-left: -1rem;
  margin-top: -0.5rem;
  cursor: pointer;
}

#estimate-comments .comments-input-container .images-container{
  display: flex;
}

/* ESTIMATE COMMENT TILE */
#estimate-comment-tile{
  margin-bottom: 2rem;
}

#estimate-comment-tile .comment-header{
  display: flex;
  align-items: center;
  width: 80%;
  margin-bottom: 0.5rem;
}

#estimate-comment-tile .comment-header .profile-pic{
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 0.5rem;
}

#estimate-comment-tile .comment-header .comment-details{

}

#estimate-comment-tile .comment-header .comment-details h6{
  margin-bottom: 0.2rem;
  font-size: 0.9rem;
  font-family: 'rand medium';
}

#estimate-comment-tile .comment-header .comment-details p{
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 0.7rem;
  font-family: 'rand regular';
}

#estimate-comment-tile .self-comment, #estimate-comment-tile .other-comment{
  border-radius: 5px;
  padding: 0.7rem;
}

#estimate-comment-tile .self-comment p, #estimate-comment-tile .other-comment p{
  font-family: 'rand medium';
  font-size: 0.9rem;
}

#estimate-comment-tile .self-comment{
  background-color: #f1f1f1;
  width: 80%;
}

#estimate-comment-tile .other-comment{
  background-color: #c4c4c4;
  width: 80%;
}

#estimate-comment-tile .self-comment-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#estimate-comment-tile .other-comment-container{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.comment-media-wrapper{
  display: flex;
  flex-wrap: wrap;
}

.comment-media-wrapper .comment-media-tile{
  width: 8rem;
  height: 8rem;
  margin: 0.2rem;
  display: flex;
  align-items: center;
  border: 0.8px solid #c3c3c3;
  border-radius: 5px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.16);
}

.comment-media-wrapper .comment-media-tile .media{
  width: 7.9rem;
  max-height: 8rem;
  border-radius: inherit;
}

.comment-media-wrapper .comment-media-tile .image-actions{
  width: 1.5rem;
  position: absolute;
  align-self: flex-end;
  padding-bottom: 0.2rem;
  opacity: 0;
  cursor: pointer;
}

.comment-media-wrapper .comment-media-tile a{
  text-decoration: none;
  display: flex;
  justify-content: center;
  position: absolute;
  font-weight: 100;
  height: 8rem;
  margin-left: 0.8rem;
  margin-bottom: 0.2rem;
}

.comment-media-wrapper .comment-media-tile:hover .image-actions, .comment-media-wrapper .comment-media-tile:active .image-actions, .comment-media-wrapper .comment-media-tile:focus .image-actions{
  opacity: 1;
}

.pdf-view{
  width: 20rem;
  border: 0.8px solid #c3c3c3;
  border-radius: 5px;
  box-shadow: 2px 2px 6px rgb(0 0 0 / 16%);
  display: flex;
  align-items: center;
  margin: 0.5rem;
}

#pdf-container .pdf-maximize{
  position: absolute;
  display: flex;
  align-items: flex-end;
  opacity: 0;
}

#pdf-container:hover .pdf-maximize{
  opacity: 1;
}

.pdf-view .page-handlers{
  position: absolute;
  justify-content: space-between;
  display: flex;
  width: inherit;
  opacity: 0;
}

.pdf-view:hover .page-handlers{
  opacity: 1;
}

.pdf-view .react-pdf__Document{
 width: inherit;
 border-radius: 5px;
}

.pdf-view .react-pdf__Document .react-pdf__Page{
  width: inherit;
  border-radius: 5px;
}

.pdf-view .react-pdf__Document .react-pdf__Page .react-pdf__Page__canvas{
  height: auto!important;
  width: inherit!important;
  border-radius: 5px;
}



.pdf-modal .react-pdf__Page{
  width: 100%;
  display: flex;
  justify-content: center;
}

.pdf-modal .react-pdf__Page .react-pdf__Page__canvas{
  height: auto!important;
  width: inherit!important;
}

/* media query for max phone width */
@media (max-width: 500px){
  .header-space {
    background: #f4f4f4;
  }

  .header-space h3{
    padding-left:2rem;
  }

  #manual-estimation{
    display: flex;
    flex-direction: column;
  }

  /* ACTIONS */
  #manual-estimation .actions{
    flex: none;
  }

  /* DETAILS */
  #manual-estimation .details .details-container{
    padding: 0 2rem 0 2rem;
  }

  /* ESTIMATE */
  #estimate input.form-control, #estimate textarea, #estimate .input-group-text{
    border: none;
    background-color: #fff;
  }

  /* editable labels */
  #estimate .editable-labels{
    background-color: transparent;
  }

  /* ESTIMATE COMMENT TILE */
  #estimate-comment-tile .self-comment{
    width: 100%;
  }
  
  #estimate-comment-tile .other-comment{
    width: 100%;
  }

  #estimate-comment-tile .comment-header{
    width: 100%;
  }
}

.react-calendar__tile--now {
  background: white;
}
.react-calendar__tile--now:enabled:hover {
  background: #e6e6e6;
}

.react-calendar__month-view__days__day--weekend {
  color: #0047FF;
}