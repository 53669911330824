#learn-more{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#learn-more h3{
  text-align: center;
  font-weight: 400;
  font-family: sofia-pro;
  margin-top: 4rem;
  margin-bottom: 2rem;
  font-size: 1.75rem;
}

#learn-more .btn{
  height:max-content;
  text-transform: capitalize;
  margin:0;
  /* width: 120px; */
  min-width: 120px;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  background-color: #999999;
}

#learn-more .btn:hover, #learn-more .btn:active{
  background-color: #000000;
}

#learn-more h5{
  margin-bottom: 0.5rem;
  font-weight: bold;
  text-align: center;
  font-size: 1.25rem;
}

#learn-more p{
  color:#999999;
  text-align: center;
}

#learn-more h1{
  font-weight: 400;
  margin-bottom: 2rem;
}