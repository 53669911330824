a.app-nav,
p.app-nav {
    text-align: center;
    font-size: 1rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
    cursor: pointer;
    /* color: white; */
    font-weight: 700;
    font-family: "sofia-pro";
}

p.cancel {
    font-size: 2rem;
    /* margin-top: 0.8rem; */
    font-weight: 400;
}

div.cancel {
    /* margin-top: 50%; */
    /* transform: translate(0, -50%); */
}
