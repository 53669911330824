#basic-info-container{
  font-family: sofia-pro;
}

#basic-info-container .form-label{
  font-weight: 400;
}

#basic-info-container .button-container{
  width:100%;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 1rem;
}

#basic-info-container textarea{
  height: 100px;
}

#basic-info-container h2{
  width:100%;
  text-align: center;
  font-family: sofia-pro;
  font-weight: 400;
  padding-top: 1rem;
}

#basic-info-container .form-group{
  padding:0;
}

#basic-info-container .form-control{
  border-radius: 5px;
}

.image-upload>input {
  display: none;
}

#basic-info-container ::placeholder{
  color:#a3a3a3;
  font-weight: 100;
  font-size: 14px;
}

#basic-info-container :-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #a3a3a3;
  font-weight: 100;
  font-size: 14px;
}

#basic-info-container ::-ms-input-placeholder { /* Microsoft Edge */
  color: #a3a3a3;
  font-weight: 100;
  font-size: 14px;
}

@media (min-width: 300px){
  #basic-info-container{
    padding-left: 1rem;
    padding-right: 1rem;
  }
  #basic-info-container .button-container{
    width:100%;
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 600px){
  #basic-info-container{
    padding-left: 1rem;
    padding-right: 1rem;
  }
  #basic-info-container .button-container{
    width:100%;
    display: flex;
    justify-content: flex-end;
  }
}

@media (min-width: 1000px){
  #basic-info-container{
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

.PhoneInputInput{
  margin: 0;
  padding: 0.5rem 1.3rem;
  font-size: 1rem;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ced4da;
}

.PhoneInput{
  width: 100%;
}