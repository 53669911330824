body {
    font-family: sofia-pro, sans-serif;
    /* background-color: #dddddd; */
}

#account {
    /* color: white; */
}

/* #account .navbar-brand {
    font-family: bigmoore, serif;
    font-size: 2rem;
    text-align: center;
    width: 100%;
}

#account .navbar {
    margin-bottom: 5rem;
    background-color: transparent !important; */
/* } */

#account h2 {
    text-align: center;
    /* font-size: 1.2rem; */
    /* font-weight: 700; */
    margin-bottom: 2rem;
}

#account .sign-out-btn {
    margin-bottom: 2rem;
    margin-top: 2rem;
    background-color: white;
    color: black;
    border: 1px solid black;
}

#account h2:hover img {
    display: inline-block;
}

#account .widget-name:hover img {
    display: inline-block;
}

#account h2 input {
    border: none;
    border-bottom: 1px solid black;
    width: 10rem;
    border-radius: 0px;
    display: inline-block;
}

#account .widget-name input {
    border: none;
    font-size: 1.25rem;
    border-bottom: 1px solid black;
    width: 10rem;
    border-radius: 0px;
    display: inline-block;
    text-align: center;
}

#account h2 img {
    height: 1.5rem;
    display: none;
    margin-left: 0.5rem;
}

.canvas-widget .widget-name img {
    height: 1.5rem;
    display: none;
    margin-left: 0.5rem;
}

.canvas-widget .widget-name h5 {
    height: 1.5rem;
    font-size: 1.5rem;
    font-weight: 700;
}

#profile-pic-form {
    visibility: hidden;
    position: absolute;
}

#account .profile-pic-wrapper img {
    border-radius: 10rem;
}

#account h6 {
    font-size: 1.2rem;
    text-align: center;
}

#account .canvas-widget {
    padding: 1rem;
    /* border: 1px solid black; */
}
#account .canvas-widget > div {
    /* border: 1px solid #cccccc; */
    border-radius: 10px;
    /* padding: 2rem; */
    /* background-color: #333; */
    height: 100%;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding-bottom: 2rem;
}

#account .canvas-widget .widget-name {
    text-align: center;
    font-weight: 400;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

#account .canvas-widget:hover {
    cursor: pointer;
    transform: scale(1.02);
}

#account .canvas-widget p {
    text-align: center;
    margin-bottom: 0px;
    /* color: #ccc; */
    font-size: 0.9rem;
}

#account .canvas-widget img {
    /* margin-left: 50%;
    transform: translate(-50%, 0); */
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

#account .account-details {
    padding: 2rem;
    /* background-color: white; */
}

#profilePic .editWrapper
{
    background-color: #333;
    position: absolute;
    right: 0.5rem;
    bottom: 0.5rem;
    border-radius: 5rem;
    border: 5px solid white;
    transition: ease-out 0.2s;

}

#profilePic .editWrapper label
{
    margin-bottom: 0rem;
    padding: 1rem;
    cursor: pointer;

}

#profilePic .editWrapper img
{
    height: 1.5rem;
    width: unset;
}

#profilePic .editWrapper:hover
{
    background-color:black;
}

#account .profileDetails
{
    text-align:  center;
}

#account .profileDetails h1
{
    text-align :center; 
    font-weight:500;
    font-size:3rem;
    margin-bottom:0.5rem;
}

.file-input
{
    display: none;
}


@media (min-width:992px){
    #account .profileDetails
    {
        text-align:  left;
    }
    
    #account .profileDetails h1
    {
        text-align :left; 
    }

    
    #canvas-manager h2
    {
        text-align: left;
    }
}